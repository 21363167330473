import { Component, createRef } from "react";
import HeaderSistemaFabricante from "./Components/HeaderSistemaFabricante";
import { Toast } from "primereact/toast";
import Constante from "../../util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Util from "../../util/Util";
import { Dropdown } from "primereact/dropdown";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class FabricanteEquipamentoDetalhe extends Component {
  toast = createRef();
  constante = new Constante();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEquipamento: new Pessoa(),
    pessoaEstacao: null,
    listaEstacao: [],
    salvarCarregando: false,
    inputError: {
      nome: false,
      estacao: false,
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let pessoaEquipamento = new Pessoa();
    let parametro = await this.util.buscarParametros();
    let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id, this.constante.seqUsuario);
    if (retorno?.objeto) {
      pessoaEquipamento = retorno.objeto;
    };

    let listaEstacao = await this.listarEstacao(pessoaUsuario);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaEquipamento: pessoaEquipamento,
      listaEstacao: listaEstacao
    })
  }

  async listarEstacao(pPessoa) {
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoa) {
      pessoaUsuario = pPessoa;
    }

    let listaPequisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEstacao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaEstacao = retorno.objeto;
    console.log(listaEstacao);
    this.setState({ listaEstacao: listaEstacao });
    return listaEstacao;
  }


  async salvar() {
    this.setState({ salvarCarregando: true });
    let pessoaEquipamento = this.state.pessoaEquipamento;
    let inputError = this.state.inputError;
    let inputsOk = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });

    if (!pessoaEquipamento.nome) {
      inputError.nome = true;
    }

    Object.keys(inputError).forEach(pKey => {
      if (inputError[pKey]) {
        inputsOk = false
      }
    })

    this.setState({
      inputError: inputError,
    })

    if (!inputsOk) {
      this.setState({ salvarCarregando: false })
      return;
    }

    let retorno = await this.pessoaService.salvar(pessoaEquipamento);
    pessoaEquipamento = retorno.objeto;

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Equipamento salvo com sucesso.' });
    this.setState({
      pessoaEquipamento: pessoaEquipamento,
      salvarCarregando: false
    })
  }
  render() {
    return (<div>
      <HeaderSistemaFabricante />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }}
              onClick={() => window.location.href = '/fabricante/equipamento'}
              icon='pi pi-arrow-left'
            />
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Equipamento detalhe</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Equipamento detalhe</h2>
            </div>
          </div>
          <Button
            loading={this.state.salvarCarregando}
            label="Salvar"
            onClick={() => this.salvar()}
          />
        </div>

        <form
          onSubmit={e => {
            e.preventDefault();
          }}>
          <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
            <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
            <h3>Dados do equipamento</h3>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
            <div>
              <div style={{ width: 150, height: 150, border: '1px solid lightgray' }}>

              </div>
            </div>

            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, height: 150 }}>
              <div style={{ width: '100%' }}>
                <label>Nome</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                  onChange={pEvento => this.setState({
                    pessoaEquipamento: {
                      ...this.state.pessoaEquipamento,
                      nome: pEvento.target.value
                    }
                  })}
                  value={this.state.pessoaEquipamento.nome || ''} />
                {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
              </div>

              <div>
                <label>
                  Estação
                </label><br />
                <Dropdown
                  optionLabel='_pessoaRelacionadaNome'
                  placeholder="Selecione"
                  style={{ width: '100%', borderColor: this.state.inputError.estacao ? 'red' : '' }}
                  options={this.state.listaEstacao}
                  value={this.state.pessoaEstacao}
                  onChange={(pEvento) => this.setState({ pessoaEstacao: pEvento.value })}
                />
                {this.state.inputError.estacao && <small style={{ color: 'red' }}>Estação inválido</small>}
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
    )
  }
}