import { Component } from "react";
import Util from '../../util/Util';
import Constante from '../../util/Constante';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import HeaderSistemaFabricante from "./Components/HeaderSistemaFabricante";


export default class FabricanteEstoque extends Component {
  util = new Util();
  constante = new Constante();

  state = {
    carregando: false
  }

  render() {
    return <div>
      <HeaderSistemaFabricante />

      <main style={{ width: "98%", margin: "20px auto" }}>

        <div
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
          }}
        >
          <h1 style={{ fontSize: 22, margin: 0 }}>Estoque</h1>
          <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estoque</h2>
        </div>

        {/* FILTRO */}
        <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

            <div>
              <label>INFO</label><br />
              <InputText
                style={{
                  width: "100%",
                }}
              />
            </div>

            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button label="Buscar" className="p-button-raised" onClick={() => { return }}
              loading={this.state.carregando}
            />
          </div>

        </div>

        {/* FIM FILTRO */}


           {/* TABELA */}

           <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          value={[]}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
        >
          <Column
            header='INFO'
            field="codigo"
            sortable
          />

          <Column
            header='INFO'
            field="nome"
            sortable
          />

          <Column
            header='Data cadastro'
            sortable
            body={item => this.util.formatarData(item.dataCadastro)}
          />
        </DataTable>

        {/* FIM DA TABELA */}
    
      </main>
    </div>
  }
}