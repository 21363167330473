import { Component, createRef } from "react";
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";


export default class Equipamento extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  cepService = new CepService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEquipamento: new Pessoa(),
    endereco: new Endereco(),
    buscarCepCarregando: false,
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    },
    inputError: {
      nome: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaEquipamento = await this.listar(pessoaUsuario);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaEquipamento: listaEquipamento,
    })
  }

  async listar(pPessoa) {
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoa) {
      pessoaUsuario = pPessoa;
    }

    let listaPequisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEquipamento;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaEquipamento = retorno.objeto;
    console.log(listaEquipamento);
    this.setState({ listaEquipamento: listaEquipamento });
    return listaEquipamento;
  }

  async salvar() {
    this.setState({ salvarCarregando: true });
    let pessoaEquipamento = this.state.pessoaEquipamento;
    let inputError = this.state.inputError;
    let inputsOk = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });

    if (!pessoaEquipamento.nome) {
      inputError.nome = true;
    }

    Object.keys(inputError).forEach(pKey => {
      if (inputError[pKey]) {
        inputsOk = false
      }
    })

    this.setState({
      inputError: inputError,
    })

    if (!inputsOk) {
      this.setState({ salvarCarregando: false })
      return;
    }

    pessoaEquipamento.seqUsuario = this.constante.seqUsuario;
    pessoaEquipamento.seqPessoaPerfil = this.constante.seqPessoaPerfilEquipamento;
    let retorno = await this.pessoaService.salvar(pessoaEquipamento);
    pessoaEquipamento = retorno.objeto;


    let relacionamento = new PessoaRelacionamento();
    relacionamento.seqUsuario = this.constante.seqUsuario;
    relacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEquipamento
    relacionamento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
    relacionamento.seqPessoaRelacionada = pessoaEquipamento._seqPessoa;
    retorno = await this.pessoaService.salvarRelacionamento(relacionamento);

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Equipamento salvo com sucesso.' });

    this.setState({
      salvarCarregando: false,
      dialogNovoEquipamento: false,
      pessoaEquipamento: new Pessoa(),
    })

    setTimeout(() => {
      window.location.href = `/usuario/equipamento-detalhe?id=${pessoaEquipamento._seqPessoa}`
    }, 500);
    return
  }

  async buscarCep() {
    this.setState({ salvarCarregando: true, buscarCepCarregando: true });
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputOk = true;

    Object.keys(inputError).forEach(pKeys => {
      inputError[pKeys] = false
    })

    if (!endereco.cep) {
      inputError.cep = true
    }

    Object.keys(inputError).forEach(pKeys => {
      if (inputError[pKeys]) {
        inputOk = false
      }
    });

    this.setState({
      inputError: inputError,
      salvarCarregando: false,
      buscarCepCarregando: false
    })

    if (!inputOk) {
      return
    }

    let retorno = await this.cepService.buscarCep(endereco.cep);

    endereco.logradouro = retorno.logradouro;
    endereco.complemento = retorno.complemento;
    endereco.bairro = retorno.bairro;
    endereco.cidade = retorno.localidade;
    endereco.uf = retorno.uf;

    this.setState({
      endereco: endereco,
      buscarCepCarregando: false,
      salvarCarregando: false,
    })
  }

  render() {
    return (<div>
      <HeaderSistemaCliente />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />

        {/* CADASTRO NOVO EQUIPAMENTO */}
        <Dialog
          header='Novo equipamento'
          style={{ minWidth: '700px', minHeight: '300px' }}
          visible={this.state.dialogNovoEquipamento}
          onHide={() => this.setState({ pessoaEquipamento: new Pessoa(), endereco: new Endereco(), dialogNovoEquipamento: false, })}
        >
          <form
            onSubmit={(pEvento) => {
              pEvento.preventDefault();
              this.salvar();
            }}
            style={{ width: '100%', height: '100%' }}>

            <section style={{ width: '100%', display: 'flex', alignItems: 'flex-end', gap: 20, border: '1px solid lightgray', padding: 20 }}>
              <div style={{ width: '100%' }}>
                <label for='form-cadastro-estacao-nome'>
                  Nome do equipamento
                </label><br />
                <InputText
                  id='form-cadastro-estacao-nome'
                  onChange={(pTexto) => this.setState({
                    pessoaEquipamento: {
                      ...this.state.pessoaEquipamento, nome: pTexto.target.value
                    }
                  })}
                  value={this.state.pessoaEquipamento.nome || ''}
                  style={{ width: '100%', borderColor: this.state.inputError.nome ? 'red' : '' }}
                />
                {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
              </div>
              <Button
                type="submit"
                style={{ width: '60%' }}
                label="Salvar"
              />
            </section>
          </form>
        </Dialog>

        <div
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            display: "flex",
            justifyContent: 'space-between'
          }}
        >

          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>Equipamentos</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Equipamentos</h2>
          </div>

          <div>
            <Button loading={this.state.carregando} label="Novo" className="p-button-raised"
              onClick={() => this.setState({ dialogNovoEquipamento: true })} style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </div>

        {/* FILTRO */}
        <form
          onSubmit={pEvento => {
            pEvento.preventDefault();
            this.listar();
          }}
          style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
            <div>
              <label>Nome</label><br />
              <InputText
                onChange={pEvento => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pEvento.target.value
                    }
                  })
                }}
                value={this.state.filtro.nome || ''}
                placeholder="Nome"
                style={{ width: '100%', height: 40 }}
              />
            </div>

            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="submit"
              loading={this.state.carregando}
              label="Buscar"
              className="p-button-raised" style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </form>
        {/* FIM FILTRO */}


        {/* TABELA */}
        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
          value={this.state.listaEquipamento}
          loading={this.state.carregando}
        >
          <Column header='Nome'
            field='_pessoaRelacionadaNome'
            sortable
            body={pPessoa => <a href={'/usuario/equipamento-detalhe?id=' + pPessoa._seqPessoaRelacionada}>{pPessoa._pessoaRelacionadaNome}</a>}
          />

          <Column header='Data cadastro'
            field='dataCadastro'
            sortable
            body={pPessoa => this.util.formatarData(pPessoa.dataCadastro)}
          />
        </DataTable>
        {/* FIM DA TABELA */}
      </main>
    </div>
    )
  }
}