import { Component, createRef } from "react";
import Header from "../../components/Header";
import LogoSemFundo from "../../assets/images/logo-sem-fundo-verde.png";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from "primereact/messages";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Constante from "../../util/Constante";
import Util from "../../util/Util";

export default class Login extends Component {
  mensagens = createRef();
  constante = new Constante();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    email: '',
    senha: '',
    inputError: {
      email: false,
      senha: false,
    },
    carregando: false,
  }


  async validarAcesso() {
    this.setState({ carregando: true });
    let listaPesquisa = [];
    let pessoa = new Pessoa();
    let inputError = this.state.inputError;
    let inputsOK = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });

    if (!this.state.email) {
      inputError.email = true;
    }

    if (!this.state.senha) {
      inputError.senha = true;
    }

    if (!this.util.validarEmail(this.state.email)) {
      inputError.email = true;
    }

    Object.keys(inputError).forEach(pKey => {
      if (inputError[pKey]) {
        inputsOK = false;
      }
    });

    this.setState({ inputError: inputError });
    if (!inputsOK) {
      this.setState({ carregando: false })
      return;
    }

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = ` AND pessoa.seq_pessoa_perfil in ('${this.constante.seqPessoaPerfilAdministrador}', '${this.constante.seqPessoaPerfilCliente}', '${this.constante.seqPessoaPerfilFornecedor}', '${this.constante.seqPessoaPerfilFabricante}')`;
    pesquisa.conteudo = this.constante.seqPessoaPerfilAdministrador;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'MANUAL';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.email_plataforma_lirida';
    pesquisa.conteudo = this.state.email;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    console.log(listaPesquisa);
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    pessoa = retorno.objeto[0];
    if (!pessoa) {
      this.mensagens.current.show([{ severity: 'error', summary: 'Conta não encontrada.', sticky: true }]);
      this.setState({ carregando: false })
      return;
    }

    if (this.state.senha !== pessoa?.senha) {
      this.mensagens.current.show([{ severity: 'error', summary: 'Senha incorreta', sticky: true }]);
      this.setState({ carregando: false })
      return;
    }
    this.setState({ carregando: false })
    this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);

    if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador) {
      window.location.href = '/admin/cliente';
      return
    } else if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilFornecedor) {
      window.location.href = '/fornecedor/ordem-de-servico';
      return
    } else if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilFabricante) {
      window.location.href = '/fabricante/equipamento';
      return
    } else if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilCliente) {
      window.location.href = '/usuario/dashboard';
      return
    }
  }

  render() {
    return <div>
      <Header />
      <main style={{ width: '90%', padding: '50px 0', margin: "0 auto", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ width: '34%', height: '20%' }}>
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 360 }}
            src={LogoSemFundo}
            alt="Logo Ecovision." />
        </div>

        <form onSubmit={e => { e.preventDefault(); this.validarAcesso(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
          <Messages ref={this.mensagens} />

          <h1>Login</h1>

          <div>
            <label for='email-login'>E-mail</label><br />
            <InputText
              id='email-login'
              onChange={pEvento => this.setState({
                email: pEvento.target.value
              })}
              value={this.state.email || ''}
              type='email'
              style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)' }} />
            {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>E-mail inválido</small>}
          </div>

          <div>
            <label for='senha-login'>Senha</label><br />
            <InputText
              id='senha-login'
              onChange={pEvento => this.setState({
                senha: pEvento.target.value
              })}
              value={this.state.senha || ''}
              type='password'
              style={{ width: '100%', borderColor: this.state.inputError.senha && 'var(--red-600)' }} />
            {this.state.inputError.senha && <small style={{ color: 'var(--red-600)' }}>Senha inválida</small>}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button label="Recuperar senha" type="button" className="p-button-link" style={{ padding: 0, color: this.constante.corPrimaria }} onClick={() => window.location.href = '/recuperar_senha'} />
            <Button label="Entrar" type="submit" loading={this.state.carregando} style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }} />
          </div>
        </form>
      </main>
    </div>
  }
}