import UtilApi from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';

export default class Util {
  constante = new Constante();
  utilApi = new UtilApi(this.constante.token, this.constante.url_base);

  async buscarUrlBaseArquivo() {
    let retorno = await this.utilApi.buscarUrlBaseArquivo();
    retorno = await retorno.json();
    return retorno;
  }

  async pegarBase64(pArquivo) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(pArquivo);
      reader.onload = () => {
        let base64 = reader.result;
        base64 = base64.split(",")[1];
        resolve(base64);
      };
      reader.onerror = error => reject(error);
    })
  }

  validarEmail(pEmail) {
    let emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(pEmail);
  }

  async validarDocumento(pDocumento) {
    let retorno = await this.utilApi.validarDocumento(pDocumento);
    retorno = await retorno.json();
    return retorno;
  }

  async buscarLocalStorage(pCampo) {
    let retorno = localStorage.getItem(pCampo);
    retorno = await JSON.parse(retorno);
    return retorno;
  }

  salvarLocalStorage(pCampo, pValor) {
    let valor = JSON.stringify(pValor);
    localStorage.setItem(pCampo, valor);
    console.log(pCampo + ' salvo localstorage com sucesso')
  }

  removerLocalStorage(pCampo) {
    let retorno = localStorage.removeItem(pCampo);
    return retorno;
  }

  buscarParametros() {
    let listaParametrosSplitados = window.location.search.replace('?', '').split('&');
    let parametros = {};

    for (let i = 0; i < listaParametrosSplitados.length; i++) {
      let listaChaveValor = listaParametrosSplitados[i].split('=');
      parametros[listaChaveValor[0]] = listaChaveValor[1];
    }

    return parametros;
  }

  async listarBanco(pConteudo) {
    this.listaBanco = await this.utilApi.listarBanco(pConteudo);
    this.listaBanco = await this.listaBanco.json();
    return this.listaBanco;
  }

  formatarCEP(pTexto) {
    this.texto = pTexto;
    return this.texto.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  formatarTelefone(value) {
    let r = value.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (value.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }

    return r;
  }

  formatarCPF(pTexto) {
    let t = pTexto;
    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }
  }

  formatarCNPJ(pTexto) {
    let t = pTexto;
    return t.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }


  formatarData(pData) {
    if (!pData) {
      return ''
    }
    this.data = String(pData);
    this.data = this.data.split('T')[0];
    this.data = this.data.replace('-', '/').replace('-', '/');
    return this.data;
  }

  formatarObjetoDataParaDataBanco(pData) {
    return pData.toLocaleDateString("pt-br").split("/").join("-") + 'T00:00:00';
  }


  enviarEmailAcesso(pPessoa) {
    this.utilApi.enviarEmail({
      seqEmail: this.constante.seqEmailAcesso,
      email: pPessoa.emailPlataformaLirida,
      parametro: {
        nome: pPessoa.nome,
        emailPlataformaLirida: pPessoa.emailPlataformaLirida,
        senha: pPessoa.senha
      }
    });
  }


  async listarComFiltro(pListaPesquisa) {
    let cabecalho = new Headers();
    cabecalho.append("token", this.constante.token);
    cabecalho.append("Content-Type", "application/json");

    let res = await fetch(this.constante.url_base + "estoque/listar_com_filtro", {
      method: 'POST',
      headers: cabecalho,
      redirect: 'follow',
      body: JSON.stringify(pListaPesquisa)
    })
    res = await res.json()
    return res;
  }


  obterMes(dateString) {
    const [date, time] = dateString.split('T');
    const [day, month, year] = date.split('-');
    return new Date(`${year}-${month}-${day}`).getMonth();
  }

  obeterNomeDoMes(monthIndex) {
    const monthNames = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ];

    return monthNames[monthIndex];
  }


  formatarDataHora(pdata, phora) {
    const dataString = pdata;
    const horaString = phora;

    // Extrair os valores de data
    const dataParts = dataString.split("-");
    const dia = parseInt(dataParts[0], 10);
    const mes = parseInt(dataParts[1], 10) - 1; // Os meses são indexados a partir de zero no objeto Date
    const ano = parseInt(dataParts[2].substring(0, 4), 10);

    // Extrair os valores de hora
    const horaParts = horaString.split(":");
    const hora = parseInt(horaParts[0], 10);
    const minutos = parseInt(horaParts[1], 10);
    const segundos = 0; // Definir os segundos como zero

    // Criar o objeto Date com os valores de data e hora
    const dataHora = new Date(ano, mes, dia, hora, minutos, segundos);
    let dataFinal = dataHora.toLocaleDateString('pt-BR');
    let horaFinal = dataHora.toLocaleTimeString('pt-BR');
    return `${dataFinal} ${horaFinal}`
  }


  formatarDataBancoParaData(pDataBanco) {
    const data = pDataBanco.split("T")[0]
    const [dia, mes, ano] = data.split('-')
    return new Date(Number(ano), Number(mes) - 1, Number(dia));
  }

  formatarDataComHora(pData, pHora) {
    const data = pData.split("T")[0]
    const [dia, mes, ano] = data.split('-');
    const [hora, minuto] = pHora.split(':');
    let datafinal = new Date(Number(ano), Number(mes) - 1, Number(dia), Number(hora), Number(minuto));
    return datafinal
  }
}