import { Component, createRef } from "react";
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { Steps } from "primereact/steps";


export default class Estacao extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  cepService = new CepService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEstacao: new Pessoa(),
    endereco: new Endereco(),
    indexStepAtivo: 0,
    buscarCepCarregando: false,
    salvarCarregando: false,
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    },
    inputError: {
      nome: false,
      tipoEstacao: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaEstacao = await this.listar(pessoaUsuario);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaEstacao: listaEstacao,
    })
  }

  async listar(pPessoa) {
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoa) {
      pessoaUsuario = pPessoa;
    }

    let listaPequisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_dono';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);


    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilEstacao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    if (this.state.filtro.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.nome';
      pesquisa.conteudo = this.state.filtro.nome
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPequisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroInicial)
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPequisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroFinal)
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPequisa.push(pesquisa);
    }

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaEstacao = retorno.objeto;

    this.setState({ listaEstacao: listaEstacao });
    return listaEstacao;
  }


  async salvar() {
    this.setState({ salvarCarregando: true });
    let pessoaUsuario = this.state.pessoaUsuario
    let pessoaEstacao = this.state.pessoaEstacao;
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputsOk = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });
    if (this.state.indexStepAtivo === 0) {
      if (!pessoaEstacao.nome) {
        inputError.nome = true;
      }

      if (!pessoaEstacao.tags0) {
        inputError.tipoEstacao = true;
      }
    }

    if (this.state.indexStepAtivo === 1) {
      if (!endereco.cep) {
        inputError.cep = true;
      }

      if (!endereco.logradouro) {
        inputError.logradouro = true;
      }

      if (!endereco.numero) {
        inputError.numero = true;
      }

      if (!endereco.bairro) {
        inputError.bairro = true;
      }

      if (!endereco.cidade) {
        inputError.cidade = true;
      }

      if (!endereco.uf) {
        inputError.uf = true;
      }
    }

    Object.keys(inputError).forEach(pKey => {
      if (inputError[pKey]) {
        inputsOk = false
      }
    })

    this.setState({
      inputError: inputError,
    })

    if (!inputsOk) {
      this.setState({ salvarCarregando: false })
      return;
    }

    if (this.state.indexStepAtivo < 1) {
      this.setState({
        indexStepAtivo: this.state.indexStepAtivo + 1
      });
      return;
    };

    pessoaEstacao.seqUsuario = this.constante.seqUsuario;
    pessoaEstacao.seqPessoaPerfil = this.constante.seqPessoaPerfilEstacao;
    pessoaEstacao.seqPessoaDono = pessoaUsuario._seqPessoa
    let retorno = await this.pessoaService.salvar(pessoaEstacao);
    pessoaEstacao = retorno.objeto;

    endereco.seqPessoa = pessoaEstacao._seqPessoa;
    endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;
    endereco.seqUsuario = this.constante.seqUsuario;

    retorno = await this.pessoaService.salvarEndereco(endereco);
    endereco = retorno.objeto;


    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Estação salva com sucesso.' });

    this.setState({
      salvarCarregando: false,
      dialogNovaEstacao: false,
      pessoaEstacao: new Pessoa(),
      endereco: new Endereco(),
    })

    setTimeout(() => {
      window.location.href = `/usuario/estacao-detalhe?id=${pessoaEstacao._seqPessoa}`
    }, 1000)
  }

  async buscarCep() {
    this.setState({ salvarCarregando: true, buscarCepCarregando: true });
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputOk = true;

    Object.keys(inputError).forEach(pKeys => {
      inputError[pKeys] = false
    })

    if (!endereco.cep) {
      inputError.cep = true
    }

    Object.keys(inputError).forEach(pKeys => {
      if (inputError[pKeys]) {
        inputOk = false
      }
    });

    this.setState({
      inputError: inputError,
      salvarCarregando: false,
      buscarCepCarregando: false
    })

    if (!inputOk) {
      return
    }

    let retorno = await this.cepService.buscarCep(endereco.cep);

    endereco.logradouro = retorno.logradouro;
    endereco.complemento = retorno.complemento;
    endereco.bairro = retorno.bairro;
    endereco.cidade = retorno.localidade;
    endereco.uf = retorno.uf;

    this.setState({
      endereco: endereco,
      buscarCepCarregando: false,
      salvarCarregando: false,
    })
  }



  render() {
    return (<div>
      <HeaderSistemaCliente />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />

        {/* CADASTRO NOVA ESTAÇÃO */}
        <Dialog
          header='Nova estação'
          style={{ minWidth: '700px', minHeight: '350px' }}
          visible={this.state.dialogNovaEstacao}
          onHide={() => this.setState({ pessoaEstacao: new Pessoa(), endereco: new Endereco(), dialogNovaEstacao: false, indexStepAtivo: 0 })}
        >
          <Steps
            model={[{
              label: 'Dados da estação',
            }, {
              label: 'Endereço da estação',
            },]}
            activeIndex={this.state.indexStepAtivo}
          />

          <form
            style={{ width: '100%', maxWidth: 1000 }}
            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
            <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
              {this.state.indexStepAtivo === 0 && <>
                <h4 style={{ fontSize: 16, }}>
                  <span style={{ color: this.constante.corSecundaria }}>•</span> Dados da estação
                </h4>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10, marginTop: 10 }}>
                  <div>
                    <label for='form-cadastro-estacao-nome'>
                      Nome da estação
                    </label><br />
                    <InputText
                      id='form-cadastro-estacao-nome'
                      onChange={(pTexto) => this.setState({
                        pessoaEstacao: {
                          ...this.state.pessoaEstacao, nome: pTexto.target.value
                        }
                      })}
                      value={this.state.pessoaEstacao.nome || ''}
                      style={{ width: '100%', borderColor: this.state.inputError.nome ? 'red' : '' }}
                    />
                    {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                  </div>

                  <div>
                    <label for='form-cadastro-estacao-nome'>
                      Tipo de estação
                    </label><br />
                    <Dropdown
                      optionLabel='nome'
                      placeholder="Selecione"
                      style={{ width: '100%', borderColor: this.state.inputError.tipoEstacao ? 'red' : '' }}
                      value={this.state.pessoaEstacao.tags0 || ''}
                      options={[{ nome: 'Fixa', value: 'Fixa' }, { nome: 'Móvel', value: 'Móvel' }]}
                      onChange={(pEvento) => this.setState({
                        pessoaEstacao: {
                          ...this.state.pessoaEstacao, tags0: pEvento.value
                        }
                      })}
                    />
                    {this.state.inputError.tipoEstacao && <small style={{ color: 'red' }}>Tipo estação inválido</small>}
                  </div>
                </div></>}

              {this.state.indexStepAtivo === 1 && <>
                <h4 style={{ fontSize: 16, }}>
                  <span style={{ color: this.constante.corSecundaria }}>•</span> Endereço da estação
                </h4>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>

                  <div style={{ width: '100%' }}>
                    <label>CEP</label><br />
                    <div className="p-inputgroup">
                      <InputText
                        value={this.state.endereco.cep || ''}
                        type="tel"
                        style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                        onChange={pEvento => this.setState({
                          endereco: {
                            ...this.state.endereco,
                            cep: this.util.formatarCEP(pEvento.target.value)
                          }
                        })}
                      />
                      <Button
                        disabled={this.state.buscarCepCarregando}
                        loading={this.state.buscarCepCarregando}
                        style={{ backgroundColor: this.constante.corPrimaria, color: this.constante.corSecundaria, borderColor: this.constante.corPrimaria }}
                        icon="pi pi-search"
                        type="button"
                        onClick={() => this.buscarCep(this.state.endereco)}
                      />
                    </div>
                    {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
                  </div>

                  <div style={{ width: '100%' }}>
                    <label>Logradouro</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          logradouro: pEvento.target.value
                        }
                      })}
                      value={this.state.endereco.logradouro || ''} />
                    {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                  </div>

                  <div style={{ width: '100%' }}>
                    <label>Número</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          numero: pEvento.target.value
                        }
                      })}
                      value={this.state.endereco.numero || ''} />
                    {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
                  </div>

                  <div style={{ width: '100%' }}>
                    <label>Complemento</label><br />
                    <InputText
                      style={{ width: "100%" }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          complemento: pEvento.target.value
                        }
                      })}
                      value={this.state.endereco.complemento || ''} />
                  </div>

                  <div style={{ width: '100%' }}>
                    <label>Bairro</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          bairro: pEvento.target.value
                        }
                      })}
                      value={this.state.endereco.bairro || ''} />
                    {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                  </div>

                  <div style={{ width: '100%' }}>
                    <label>Cidade</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          cidade: pEvento.target.value
                        }
                      })}
                      value={this.state.endereco.cidade || ''} />
                    {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                  </div>

                  <div style={{ width: '100%' }}>
                    <label>UF</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          uf: pEvento.target.value
                        }
                      })}
                      value={this.state.endereco.uf || ''} />
                    {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
                  </div>

                </div>
              </>}

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button
                  loading={this.state.salvarCarregando}
                  type="submit"
                  label={this.state.indexStepAtivo < 1 ? 'Próximo' : 'Salvar'} />
              </div>
            </div>
          </form>
        </Dialog>

        <section
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            display: "flex",
            justifyContent: 'space-between'
          }}
        >

          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>Estações</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estações</h2>
          </div>

          <div>
            <Button loading={this.state.carregando} label="Novo" className="p-button-raised"
              onClick={() => this.setState({ dialogNovaEstacao: true })} style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </section>

        {/* FILTRO */}
        <form
          onSubmit={pEvento => {
            pEvento.preventDefault();
            this.listar();
          }}
          style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
            <div>
              <label>Nome</label><br />
              <InputText
                onChange={pEvento => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pEvento.target.value
                    }
                  })
                }}
                value={this.state.filtro.nome || ''}
                placeholder="Nome"
                style={{ width: '100%', height: 40 }}
              />
            </div>

            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="submit"
              loading={this.state.carregando}
              label="Buscar"
              className="p-button-raised" style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </form>
        {/* FIM FILTRO */}


        {/* TABELA */}
        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
          value={this.state.listaEstacao}
          loading={this.state.carregando}
        >
          <Column header='Nome'
            field='nome'
            sortable
            body={pPessoa => <a target="_blank" rel="noreferrer" href={'/usuario/estacao-detalhe?id=' + pPessoa._seqPessoa}>{pPessoa.nome}</a>}
          />

          <Column header='Data cadastro'
            field='dataCadastro'
            sortable
            body={pPessoa => this.util.formatarData(pPessoa.dataCadastro)}
          />

          {/* <Column header='Gráfico'
            field='Analisar'
            sortable
            body={pPessoa => <a target="_blank" rel="noreferrer" href={`/usuario/dashboard?idEstacao=${pPessoa._seqPessoa}&idEmpresa=${this.state.pessoaUsuario._seqPessoa}`}>Analisar</a>}
          /> */}
        </DataTable>
        {/* FIM DA TABELA */}
      </main>
    </div>
    )
  }
}