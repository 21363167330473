import { Component } from "react";
import Header from "./../components/Header"

export default class PoliticaPrivacidade extends Component {
  render() {
    return (<>
      <Header />
      <main className="h-main-container" style={{ padding: '90px 30px 30px 30px', }}>
        <section>
          <h1>Política de privacidade</h1><br />

          <p>
            Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a EcoVision. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a EcoVision serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da EcoVision pressupõe a aceitação deste acordo de privacidade. A equipa da EcoVision reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
          </p><br />

          <h2>Ligações a sites de terceiros</h2><br />

          <p>
            A EcoVision possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
          </p>
        </section>
      </main>
    </>
    )
  }
}