import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Toast } from "primereact/toast";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Messages } from "primereact/messages";
import Constante from "../util/Constante";
import Util from "../util/Util";

class Banco {
    _codigo;
    _nome;
    _seqBanco;
}

export default class FormDadosBancarios extends Component {
    toast = createRef();
    mensagens = createRef();

    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        listaBanco: [],
        banco: new Banco(),
        carregando: false,
        inputDadosBancariosError: {
            responsavelDocumento: false,
            responsavelNome: false,
            banco: false,
            agencia: false,
            digitoAgencia: false,
            conta: false,
            digitoConta: false,
        },
        endereco: new Endereco(),
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const listaBanco = await this.util.listarBanco('');
        let banco = this.state.banco;

        if (pessoaUsuario.contaCodigoBanco) {
            for (let i = 0; i < listaBanco.length; i++) {
                if (listaBanco[i]._codigo === pessoaUsuario.contaCodigoBanco) {
                    banco = listaBanco[i];
                }
            }
        }

        if (!pessoaUsuario.seqEnderecoPrincipal || !pessoaUsuario.documento) {
            this.mensagens.current.show([
                { severity: 'error', summary: 'Finalize o cadastro de seus Dados pessoais e Endereço para continuar!', closable: false, sticky: true },
            ]);
        }

        this.setState({
            banco: banco,
            pessoaUsuario: pessoaUsuario,
            listaBanco: listaBanco,
        });
    }

    async validarInputsDadosBancarios() {
        const inputDadosBancariosError = this.state.inputDadosBancariosError;
        const listaChaveinputDadosBancariosError = Object.keys(inputDadosBancariosError);

        for (let i = 0; i < listaChaveinputDadosBancariosError.length; i++) {
            inputDadosBancariosError[listaChaveinputDadosBancariosError[i]] = false;
        }

        if (this.state.pessoaUsuario.tipoDocumento === "CNPJ") {

            if (!this.state.pessoaUsuario.contaResponsavelCpf) {
                inputDadosBancariosError.responsavelDocumento = true;
            }

            if (!this.state.pessoaUsuario.contaResponsavelNome) {
                inputDadosBancariosError.responsavelNome = true;
            }

            const retorno = await this.util.validarDocumento(this.state.pessoaUsuario.contaResponsavelCpf);

            if (!retorno) {
                inputDadosBancariosError.responsavelDocumento = true;
            }
        }

        if (!this.state.pessoaUsuario.contaCodigoBanco) {
            inputDadosBancariosError.banco = true;
        }

        if (!this.state.pessoaUsuario.contaAgencia) {
            inputDadosBancariosError.agencia = true;
        }

        if (!this.state.pessoaUsuario.contaNumeroConta) {
            inputDadosBancariosError.conta = true;
        }

        if (!this.state.pessoaUsuario.contaDigitoConta) {
            inputDadosBancariosError.digitoConta = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveinputDadosBancariosError.length; i++) {
            if (inputDadosBancariosError[listaChaveinputDadosBancariosError[i]]) {
                inputOK = false;
            }
        }

        console.log(inputDadosBancariosError);

        this.setState({ inputDadosBancariosError: inputDadosBancariosError });

        return inputOK;
    }

    async salvar(pPessoa) {
        this.setState({ carregando: true });

        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        const retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retornoPessoa.objeto;

        this.setState({ pessoaUsuario: pessoaUsuario });

        console.log(pessoaUsuario);

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        if (this.props?.avancarFormulario && pessoaUsuario.contaS2pCodigo) {
            this.props.avancarFormulario();
        }

        return pessoaUsuario;
    }

    async salvarContaBanco() {
        this.setState({ carregando: true, });

        let pessoaUsuario = this.state.pessoaUsuario;

        if (!pessoaUsuario.contaDigitoAgencia) {
            pessoaUsuario.contaDigitoAgencia = '0'
        }

        if (pessoaUsuario.documento.length === 14) {
            pessoaUsuario.tipoDocumento = "CPF";
        }

        if (pessoaUsuario.documento.length === 18) {
            pessoaUsuario.tipoDocumento = "CNPJ";
        }

        pessoaUsuario = await this.salvar(pessoaUsuario);

        const retorno = await this.pessoaService.salvarContaBanco(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        if (!pessoaUsuario.contaS2pCodigo) {
            this.toast.current.show([
                { severity: 'error', summary: 'Algo de errado aconteceu! Confira seus dados bancários e tente novamente.' },
            ]);
        } else {
            await this.salvar(pessoaUsuario);

            this.toast.current.show([
                { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
            ]);
        }

        this.setState({ carregando: false })
    }

    render() {
        return <>
            <Toast ref={this.toast} />

            <Messages ref={this.mensagens} />

            <form
                onSubmit={async e => {
                    e.preventDefault();
                    const retorno = await this.validarInputsDadosBancarios();
                    if (retorno) this.salvarContaBanco();
                }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}
            >
                {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                    <>
                        <div>
                            <label>Nome do responsável</label><br />
                            <InputText
                                disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                value={this.state.pessoaUsuario.contaResponsavelNome || ''}
                                onChange={e => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        contaResponsavelNome: e.target.value
                                    }
                                })}
                                style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.responsavelNome && 'var(--red-600)' }} />
                            {this.state.inputDadosBancariosError.responsavelNome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label>CPF do responsável</label><br />
                            <InputText
                                disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                value={this.state.pessoaUsuario.contaResponsavelCpf || ''}
                                onChange={e => {
                                    if (e.target.value.length > 14) return;

                                    this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            contaResponsavelCpf: this.util.formatarCPFCNPJ(e.target.value)
                                        }
                                    })
                                }}
                                style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.responsavelDocumento && 'var(--red-600)' }} />
                            {this.state.inputDadosBancariosError.responsavelDocumento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                        </div>
                    </>
                }
                <div>
                    <label>Banco</label><br />
                    <Dropdown
                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                        filter
                        options={[
                            { ...new Banco(), _nome: "Selecione" },
                            ...this.state.listaBanco
                        ]}
                        optionLabel="_nome"
                        value={this.state.banco}
                        onChange={e => this.setState({
                            banco: e.value,
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario,
                                contaCodigoBanco: e.value._codigo
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.banco && 'var(--red-600)' }} />
                    {this.state.inputDadosBancariosError.banco && <small style={{ color: 'var(--red-600)' }}>Banco inválido</small>}
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                    <div style={{ width: '70%' }}>
                        <label>Agência</label><br />
                        <InputText
                            disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                            value={this.state.pessoaUsuario.contaAgencia || ''}
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaAgencia: e.target.value
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.agencia && 'var(--red-600)' }} />
                        {this.state.inputDadosBancariosError.agencia && <small style={{ color: 'var(--red-600)' }}>Agência inválida</small>}
                    </div>
                    <div style={{ width: '30%' }}>
                        <label>Dígito da agência</label><br />
                        <InputText
                            disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                            value={this.state.pessoaUsuario.contaDigitoAgencia || ''}
                            maxLength={3}
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaDigitoAgencia: e.target.value
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.digitoAgencia && 'var(--red-600)' }} />
                        {this.state.inputDadosBancariosError.digitoAgencia && <small style={{ color: 'var(--red-600)' }}>Dígito da agência inválido</small>}
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                    <div style={{ width: '70%' }}>
                        <label>Conta</label><br />
                        <InputText
                            disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                            value={this.state.pessoaUsuario.contaNumeroConta || ''}
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaNumeroConta: e.target.value
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.conta && 'var(--red-600)' }} />
                        {this.state.inputDadosBancariosError.conta && <small style={{ color: 'var(--red-600)' }}>Conta inválida</small>}
                    </div>
                    <div style={{ width: '30%' }}>
                        <label>Dígito da conta</label><br />
                        <InputText
                            disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                            value={this.state.pessoaUsuario.contaDigitoConta || ''}
                            maxLength={3}
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaDigitoConta: e.target.value
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputDadosBancariosError.digitoConta && 'var(--red-600)' }} />
                        {this.state.inputDadosBancariosError.digitoConta && <small style={{ color: 'var(--red-600)' }}>Dígito da conta inválido</small>}
                    </div>
                </div>
                {this.state.pessoaUsuario.contaS2pCodigo &&
                    <div style={{ display: 'flex', gap: 10 }}>
                        <i className="pi pi-check" style={{ color: "green", fontSize: 20 }}></i>
                        <p>Conta cadastrada</p>
                    </div>
                }
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    {this.state.pessoaUsuario.seqEnderecoPrincipal && <Button loading={this.state.carregando} type="submit" label={this.props?.avancarFormulario ? "Próximo" : "Salvar"} />}
                </div>
            </form>
        </>
    }
}