import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente"
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { TabPanel, TabView } from "primereact/tabview";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import FotoPessoaPaginaDetalhe from "../../components/FotoPessoaPgDetalhe";

export default class ClienteAnalistaDetalhe extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaAnalista: new Pessoa(),
    pessoaEstacao: null,
    pessoaEquipamento: null,
    listaEstacao: [],
    listaRelacionamentoEstacao: [],
    listaRelacionamentoEquipamento: [],
    salvarCarregando: false,
    salvarEstacaoCarregando: false,
    salvarEquipamentoCarregando: false,
    dialogAnalistaInativoVisivel: false,
    urlBase: '',
    inputError: {
      nome: false,
      tipoDocumento: false,
      email: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    },
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let pessoaAnalista = new Pessoa();
    let parametro = await this.util.buscarParametros();
    let urlBase = await this.util.buscarUrlBaseArquivo();
    let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id, this.constante.seqUsuario);
    if (retorno?.objeto) {
      pessoaAnalista = retorno.objeto;
    }
    let listaEstacao = await this.listarEstacao(pessoaUsuario);
    let listaRelacionamentoEstacao = await this.listarRelacionamentoEstacao(pessoaAnalista, pessoaUsuario);

    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaAnalista: pessoaAnalista,
      urlBase: urlBase,
      listaEstacao: listaEstacao,
      listaRelacionamentoEstacao: listaRelacionamentoEstacao,
    })
  }

  async listarEstacao(pPessoaUsuario) {
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    };

    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_dono';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilEstacao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaEstacao = retorno.objeto;

    this.setState({
      listaEstacao: listaEstacao,
    })

    return listaEstacao;
  }

  async listarRelacionamentoEstacao(pPessoaAnalista, pPessoaUsuario) {
    let pessoaAnalista = this.state.pessoaAnalista;
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaAnalista) {
      pessoaAnalista = pPessoaAnalista;
    };
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario
    }

    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaAnalista._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEstacao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.tags0';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    console.log(listaPesquisa)
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaRelacionamentoEstacao = retorno.objeto;

    console.log(listaRelacionamentoEstacao)
    for (const relacionamentoEstacao of listaRelacionamentoEstacao) {
      relacionamentoEstacao._pessoaRelacionamentoDataCadastro = this.util.formatarDataBancoParaData(relacionamentoEstacao._pessoaRelacionamentoDataCadastro)
    }
    this.setState({
      listaRelacionamentoEstacao: listaRelacionamentoEstacao,
    })

    return listaRelacionamentoEstacao;
  }

  async salvarEstacao() {
    let pessoaUsuario = this.state.pessoaUsuario
    let pessoaAnalista = this.state.pessoaAnalista;
    let pessoaEstacao = this.state.pessoaEstacao;
    let listaRelacionamentoEstacao = this.state.listaRelacionamentoEstacao;
    if (!pessoaEstacao) {
      return
    }
    this.setState({ salvarEstacaoCarregando: true });
    this.toast.current.clear();

    for (const relacionamento of listaRelacionamentoEstacao) {
      if (relacionamento._seqPessoaRelacionada === pessoaEstacao._seqPessoa) {
        this.toast.current.show({ severity: 'error', summary: 'Estação já vinculada', detail: 'Está estação já está vinculada a este analista.' });
        this.setState({ salvarEstacaoCarregando: false });
        return
      }
    }

    let relacionamento = new PessoaRelacionamento()
    relacionamento.seqUsuario = this.constante.seqUsuario;
    relacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEstacao;
    relacionamento.seqPessoa = pessoaAnalista._seqPessoa;
    relacionamento.seqPessoaRelacionada = pessoaEstacao._seqPessoa;
    relacionamento.tags0 = pessoaUsuario._seqPessoa;
    relacionamento.tags1 = pessoaUsuario.nome;

    let retorno = await this.pessoaService.salvarRelacionamento(relacionamento);
    relacionamento = retorno.objeto

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Estação vinculada com sucesso.' });
    this.listarRelacionamentoEstacao(pessoaAnalista);
    this.setState({ salvarEstacaoCarregando: false, pessoaEstacao: null });
  }

  async inativarAnalista() {
    let pessoaAnalista = this.state.pessoaAnalista;
    let pessoaUsuario = this.state.pessoaUsuario;

    let listaRelacionamentoEstacao = this.state.listaRelacionamentoEstacao;

    let retorno = await this.pessoaService.listarRelacionamento(pessoaUsuario)
    let listaRelacionamentoPessoaUsuario = retorno.objeto
    retorno = await this.pessoaService.listarRelacionamento(pessoaAnalista);
    let listaRelacionamentoDoAnalista = retorno.objeto;

    // DELETANDO RELCIONAMENTO DO ANALISTA COM A ESTAÇÃO
    for (let i = 0; i < listaRelacionamentoEstacao.length; i++) {
      for (let j = 0; j < listaRelacionamentoDoAnalista.length; j++) {
        if (listaRelacionamentoDoAnalista[j].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoEstacao && (listaRelacionamentoDoAnalista[j].seqPessoaRelacionada === listaRelacionamentoEstacao[i]._seqPessoaRelacionada)) {
          await this.pessoaService.deletarRelacionamento(listaRelacionamentoDoAnalista[j]);
        }
      }
    }


    for (const relacionamento of listaRelacionamentoPessoaUsuario) {
      if ((relacionamento.seqTipoRelacionamento === this.constante.seqTipoRelacionamentoAnalista) && (relacionamento.seqPessoaRelacionada === pessoaAnalista._seqPessoa)) {
        relacionamento.situacao = 'INATIVO';
        this.pessoaService.salvarRelacionamento(relacionamento);
      }
    }

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Analista Excluido com sucesso.' });
    this.setState({ dialogAnalistaInativoVisivel: false })
  }

  render() {
    return (<div>
      <Dialog
        header="Excluir analista"
        style={{ minWidth: 600 }}
        visible={this.state.dialogAnalistaInativoVisivel}
        onHide={() => this.setState({ dialogAnalistaInativoVisivel: false })}>
        <h3>Deseja realmente excluir esse analista ?</h3>
        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: 10, marginTop: 10 }}>
            <Button label="Sim" onClick={() => this.inativarAnalista()} />
            <Button label="Não" onClick={() => this.setState({ dialogAnalistaInativoVisivel: false })} />
          </div>
        </div>
      </Dialog>

      <HeaderSistemaCliente />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }}
              onClick={() => window.location.href = '/usuario/analista'}
              icon='pi pi-arrow-left'
            />
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Analista detalhe</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Analista detalhe</h2>
            </div>
          </div>
          <Button

            severity="danger"
            loading={this.state.salvarCarregando}
            label="Excluir"
            onClick={() => this.setState({ dialogAnalistaInativoVisivel: true })}
          />
        </div>

        <form
          onSubmit={e => {
            e.preventDefault();
          }}>

          <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
            <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
            <h3>Dados do analista</h3>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
            <div>
              <FotoPessoaPaginaDetalhe foto={this.state.pessoaAnalista.foto} />
            </div>

            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, height: 150 }}>
              <div style={{ width: '100%' }}>
                <label><strong>Nome</strong></label><br />
                <p>{this.state?.pessoaAnalista?.nome}</p>
              </div>

              <div style={{ width: '100%' && 'red' }}>
                <label><strong>Tipo de pessoa</strong></label><br />
                {this.state?.pessoaAnalista?.tipoDocumento === 'CPF'
                  ? 'Pessoa Fisica'
                  : 'Pessoa juridica'}
              </div>

              <div style={{ width: '100%' }}>
                <label><strong>Documento</strong></label><br />
                <p>{this.state?.pessoaAnalista?.documento}</p>
              </div>

              <div style={{ width: '100%' }}>
                <label><strong>Email</strong></label><br />
                <p>{this.state?.pessoaAnalista?.emailPlataformaLirida}</p>
              </div>

              <div style={{ width: '100%' }}>
                <label><strong>Telefone</strong></label><br />
                <p>{this.state?.pessoaAnalista?.telefonePlataformaLirida}</p>
              </div>
            </div>
          </div>
        </form>

        <div style={{ width: '100%', border: '1px solid lightgray', borderRadius: 3, marginTop: 20, gap: 60, padding: 10 }}>
          <TabView>
            <TabPanel header='Estações'>
              <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20 }}>
                <div style={{ width: '20rem' }}>
                  <label>
                    Estação
                  </label><br />
                  <Dropdown
                    filter
                    filterBy="nome"
                    optionLabel="nome"
                    placeholder="Selecione"
                    style={{ width: '100%' }}
                    options={this.state.listaEstacao}
                    value={this.state.pessoaEstacao}
                    onChange={(pEvento) => {
                      this.setState({
                        pessoaEstacao: pEvento.value
                      })
                    }}
                  />
                </div>

                <Button
                  loading={this.state.salvarEstacaoCarregando}
                  label="Adicionar"
                  onClick={() => this.salvarEstacao()}
                />
              </div>

              <DataTable
                showGridlines
                removableSort
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rows={50}
                rowsPerPageOptions={[50, 60, 70, 80, 100]}
                style={{ width: "100%", marginTop: 30 }}
                value={this.state.listaRelacionamentoEstacao}
                loading={this.state.carregando}
              >
                <Column
                  sortable
                  header='Nome'
                  field="_pessoaRelacionadaNome"
                />


                <Column
                  sortable
                  header='Relacionamento'
                  field="_tipoRelacionamentoNome"
                />

                <Column
                  sortable
                  header='Data cadastro'
                  field="dataCadastro"
                  body={(pObjeto) => <p>{pObjeto._pessoaRelacionamentoDataCadastro.toLocaleDateString('pt-br')}</p>}
                />
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </main>
    </div>)
  }
}
