import { Component } from "react";
import LogoSemFundo from '../assets/images/logo-sem-fundo-branco.png';
import Constante from "../util/Constante";

export default class Header extends Component {
  constante = new Constante();
  render() {
    return <header style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, backgroundColor: this.constante.corPrimaria, }}>

      <div style={{ height: 50, width: 100 }}>
        <img
          alt="Logo branca"
          src={LogoSemFundo}
          style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
      </div>


      {/* <nav style={{ display: 'flex', alignItems: "center", gap: 10, }}>
        <Button
          style={{ color: this.constante.corSecundaria }}
          label="Profissionais"
          className="p-button-link"
          onClick={() => window.location.href = '/sistema/profissionais'} />
        <Button
          style={{ color: this.constante.corSecundaria }}
          label="Condomínio"
          className="p-button-link"
          onClick={() => window.location.href = '/sistema/condominio'} />

        <Button
          style={{ color: this.constante.corSecundaria }}
          label="Sair"
          className="p-button-link"
          onClick={() => {
            this.util.removerLocalStorage("PESSOA_USUARIO");
            window.location.href = '/sistema/login';
          }} />
      </nav> */}
    </header>
  }
}