import { Component } from "react";
import Constante from "../util/Constante";
import { Button } from "primereact/button";

export default class CabecalhoPaginaDetalhe extends Component {
  constante = new Constante();
  render() {
    return (
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          border: "1px solid lightgray",
          padding: 20,
          borderRadius: 3,
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }}
            onClick={() => window.location.href = this.props?.href}
            icon='pi pi-arrow-left'
          />
          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>{this.props?.titulo}</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>{this.props?.titulo}</h2>
          </div>
        </div>

        <div style={{ maxWidth: '70%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, flexWrap: 'wrap' }}>
          {this.props.children}
        </div>
      </section>
    )
  }
}