import { Component, createRef } from "react";
import HeaderSistemaFabricante from "./Components/HeaderSistemaFabricante";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";


export default class FabricanteEstacao extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    endereco: new Endereco(),
    listaEstacao: [],
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    },
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaEstacao = await this.listar(pessoaUsuario);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaEstacao: listaEstacao,
    })
  }

  async listar(pPessoa) {
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoa) {
      pessoaUsuario = pPessoa;
    }

    let listaPequisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEstacao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaEstacao = retorno.objeto;
    console.log(listaEstacao);
    this.setState({ listaEstacao: listaEstacao });
    return listaEstacao;
  }

  render() {
    return (<div>
      <HeaderSistemaFabricante />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        <div
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            display: "flex",
            justifyContent: 'space-between'
          }}
        >

          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>Estações</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estações</h2>
          </div>
        </div>

        {/* FILTRO */}
        <form
          onSubmit={pEvento => {
            pEvento.preventDefault();
            this.listar();
          }}
          style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
            <div>
              <label>Nome</label><br />
              <InputText
                onChange={pEvento => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pEvento.target.value
                    }
                  })
                }}
                value={this.state.filtro.nome || ''}
                placeholder="Nome"
                style={{ width: '100%', height: 40 }}
              />
            </div>

            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="submit"
              loading={this.state.carregando}
              label="Buscar"
              className="p-button-raised" style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </form>
        {/* FIM FILTRO */}


        {/* TABELA */}
        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
          value={this.state.listaEstacao}
          loading={this.state.carregando}
        >
          <Column header='Nome'
            field='_pessoaRelacionadaNome'
            sortable
            body={pPessoa => <a href={'/fabricante/estacao-detalhe?id=' + pPessoa._seqPessoaRelacionada}>{pPessoa._pessoaRelacionadaNome}</a>}
          />

          <Column header='Data cadastro'
            field='dataCadastro'
            sortable
            body={pPessoa => this.util.formatarData(pPessoa.dataCadastro)}
          />
        </DataTable>
        {/* FIM DA TABELA */}
      </main>
    </div>
    )
  }
}