import { Component } from "react"
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente";
import Constante from "../../util/Constante"
import Util from "../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa"
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService"
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CabecalhoPaginaDetalhe from "../../components/CabecalhoPaginaDetalhe";

export default class ClienteOrdemServicoDetalhe extends Component {
  constante = new Constante();
  util = new Util();
  documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEstacao: new Pessoa(),
    documento: new Documento(),
    listaRelacionamentoDocumento: [],
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    const { id } = await this.util.buscarParametros();
    let retorno = await this.documentoService.buscar(this.constante.seqUsuario, id);
    const documento = retorno.objeto;

    retorno = await this.documentoService.listarRelacionamento(documento);
    const listaRelacionamentoDocumento = retorno.objeto;
    let pessoaEstacao = await this.buscarEstacao(listaRelacionamentoDocumento);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      documento: documento,
      listaRelacionamentoDocumento: listaRelacionamentoDocumento,
      pessoaEstacao: pessoaEstacao,
    })
  }

  async buscarEstacao(pListaRelacionamentoDocumento) {
    let [relacionamento] = pListaRelacionamentoDocumento.filter(relacionamento => relacionamento.seqTipoRelacionamento === this.constante.seqTipoRelacionamentoEstacao);
    let retorno = await this.pessoaService.buscarPorSeqPessoa(relacionamento.seqPessoaRelacionada, this.constante.seqUsuario);
    let pessoaEstacao = retorno.objeto;
    return pessoaEstacao
  }

  render() {
    return (
      <div>
        <HeaderSistemaCliente />
        <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
          <CabecalhoPaginaDetalhe
            titulo='Ordem serviço detalhe'
            href='/usuario/ordem-de-servico'
          />

          <form
            onSubmit={e => {
              e.preventDefault();
            }}>
            <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
              <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
              <h3>Dados da ordem de serviço</h3>
            </div>

            <section style={{
              display: 'inline-block', alignItems: 'start', border: '1px solid lightgray', padding: 15, borderRadius: 3, marginTop: 5, gap: 60, minHeight: 90, width: '100%'
            }}>
              <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr 1fr", gap: 20 }}>
                <div style={{ width: '100%' }}>
                  <label><strong>Soliciante</strong></label><br />
                  <p>{this.state.documento._pessoaNome}</p>
                </div>

                <div style={{ width: '100%' }}>
                  <label><strong>Nome da estação</strong></label><br />
                  <p>{this.state.pessoaEstacao.nome}</p>
                </div>

                <div style={{ width: '100%' }}>
                  <label><strong>Data cadastro</strong></label><br />
                  <p>{this.util.formatarData(this.state.documento.dataCadastro)}</p>
                </div>

                <div style={{ width: '100%' }}>
                  <label><strong>Processo</strong></label><br />
                  <p>{this.state.documento._tipoDocumentoProcessoNome}</p>
                </div>
              </div>


              <div style={{ width: '100%', marginTop: 20 }}>
                <label><strong>Observação:</strong></label><br />
                <InputTextarea
                  style={{ width: '100%', fontWeight: 'bold' }}
                  disabled
                  autoResize
                  value={this.state.documento.observacao}
                />
              </div>
            </section>
          </form>

          <div style={{ width: '100%', border: '1px solid lightgray', borderRadius: 3, marginTop: 20, gap: 60, padding: 10 }}>
            <TabView>
              <TabPanel header="Relacionamento">
                <DataTable
                  showGridlines
                  removableSort
                  paginator
                  responsiveLayout="scroll"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={50}
                  rowsPerPageOptions={[50, 60, 70, 80, 100]}
                  style={{ width: "100%", marginTop: 30 }}
                  value={this.state.listaRelacionamentoDocumento}
                >
                  <Column
                    header="Nome"
                    field="_pessoaRelacionadaNome"
                  />

                  <Column
                    header="Tipo de relacionamento"
                    field="_tipoRelacionamentoNome"
                  />

                  <Column
                    header="Data cadastro"
                    field="dataCadastro"
                    body={pItem => <p>{this.util.formatarData(pItem.dataCadastro)}</p>}
                  />
                </DataTable>
              </TabPanel>
            </TabView>
          </div>
        </main>
      </div >
    )
  }
}