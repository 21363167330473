import { Component } from "react";
import Header from "../components/Header";
import Config1 from '../assets/images/config1.jpeg'
import Config2 from '../assets/images/config2.jpeg'
import Config3 from '../assets/images/config3.jpeg'

export default class ConfiguracaoConta extends Component {
  render() {
    return <div>
      <Header />

      <h1 style={{ textAlign: "center", marginTop: 10 }}>Configuração de conta</h1>

      <div style={{ width: "100%", displa: "flex", flexDirection: "column", gap: 20, marginTop: 30, padding: 10 }}>
        <ul list-style-type='disc' style={{ padding: 10 }}><li><h2>Exclusão de Conta</h2></li></ul>

        <p style={{ marginTop: 5 }}> Para que o usuario possa fazer a exclusão da sua conta,
          basta ele ir em configurações, acessar configurações avançadas e em configurações avançadas
          selecionar a opção <strong>Excluir conta</strong>, siga os passos abaixo.
        </p>

        <ul list-style-type='disc' style={{ padding: 10, marginTop: 10 }}><li><h2>Passos</h2></li></ul>

        <div style={{ display: 'flex', height: "20%", justifyContent: "space-between", }}>

          <div style={{ width: '50%', height: "50%" }}>
            <h3>Passo 01</h3>
            <img
              style={{ width: '50%', height: '100%', marginTop: 5 }}
              src={Config1}
              alt="configuração conta." />
          </div>

          <div style={{ width: '50%', height: "50%" }}>
            <h3>Passo 02</h3>
            <img
              style={{ width: '50%', height: '100%', marginTop: 5 }}
              src={Config2}
              alt="configuração conta." />
          </div>

          <div style={{ width: '50%', height: "50%" }}>
            <h3>Passo 03</h3>
            <img
              style={{ width: '50%', height: '100%', marginTop: 5 }}
              src={Config3}
              alt="configuração conta." />
          </div>

        </div>
      </div>

    </div>
  }
}