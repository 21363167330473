export default class Constante {
  // *********** PRODUÇÃO ***********
  // URL BASE
  url_base = 'https://api.lirida.com.br/v1/api/';
  //DOCUMENTO
  seqTipoDocumentoOrdemDeServico = 'a68dedb4-be1f-48a8-9f76-94c636403161';
  //DOCUMENTO PROCESSO
  seqDocumentoProcessoAberto = '94611e67-b48b-4a3b-ba6b-3bed1af2e5db';
  seqDocumentoProcessoAnalise = '32521bff-dae0-4cb0-9d7a-1d52e30e445b';



  // TOKEN PLATAFORMA
  token = '80086a7f-1dcf-426c-9e27-59b6069c14a8';

  // USUARIO PLATAFORMA LIRIDA
  seqUsuario = '6ea80fb2-c140-4425-ad86-43d05806c0f1';

  // url_base = 'http://192.168.1.67:8080/v1/api/';
  // //DOCUMENTO
  // seqTipoDocumentoOrdemDeServico = '88d46dfd-6734-41e4-9974-bee6f327066d';
  // //DOCUMENTO PROCESSO
  // seqDocumentoProcessoAberto = '165d70c3-b803-4d7c-8607-445133fb102e';
  // seqDocumentoProcessoAnalise = '163d29c9-7416-4e04-b8f5-66e6d3514cf8';

  // RELACIONAMENTO
  seqTipoRelacionamentoCliente = '694899a3-00e1-40f1-85df-5b52432fddca';
  seqTipoRelacionamentoAnalista = '0187ca75-3583-460e-829d-64da0b2ac560';
  seqTipoRelacionamentoFornecedor = '351b6312-df28-4f96-a5df-507e209bdb59';
  seqTipoRelacionamentoFabricante = '1d834d57-1d2c-49e8-9717-1111fbb6c710';
  seqTipoRelacionamentoEquipamento = 'aefefa8e-3414-4fba-992d-e910445876c4';
  seqTipoRelacionamentoEstacao = 'deada269-35c6-41f4-acab-0b0d5ed3367a';

  // PERFIL
  seqPessoaPerfilAnalista = '351a0f21-67b6-4ab1-b110-f2a5fb70547c';
  seqPessoaPerfilEstacao = '10f1419e-a869-430b-aa31-00bf2cdcb323';
  seqPessoaPerfilEquipamento = '4c91d817-a48c-41bb-a9d9-416f0fa1e972';
  seqPessoaPerfilAdministrador = 'f1108195-4831-41a8-a7f3-833dce92ac51';
  seqPessoaPerfilFabricante = '82515bbe-81eb-4fcb-a2ee-7dd693e1904c';
  seqPessoaPerfilFornecedor = 'aa5a4f6a-19ab-4ed3-b282-335efe00b8d9';
  seqPessoaPerfilCliente = '93a0b78c-ba71-4f79-b7d2-0598e2c2a5fc';

  // TIPO DE ENDEREÇO
  seqTipoEnderecoCobranca = "b4062983-6166-4c91-8319-a25287b505b2";
  seqTipoEnderecoEntrega = "7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09";

  // ENVIAR EMAIL;
  seqEmailAcesso = '57ff663e-d5be-4539-8c0c-113cd315a42e';

  // CORES
  corPrimaria = '#006837';
  corSecundaria = 'white';
}