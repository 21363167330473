import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Component, createRef } from "react";
import { Toast } from "primereact/toast";
import Util from "../util/Util";
import Constante from "../util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";

export default class FormFotoPerfil extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    urlBase: '',
    imagemCarregando: false
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let urlBase = await this.util.buscarUrlBaseArquivo();
    this.setState({
      pessoaUsuario: pessoaUsuario,
      urlBase: urlBase,
    })
  }

  async alterarImagem(pFile) {
    this.setState({ imagemCarregando: true });
    let pessoaUsuario = this.state.pessoaUsuario;
    let retorno = await this.util.pegarBase64(pFile);
    pessoaUsuario.foto = retorno;
    this.setState({ pessoaUsuario: pessoaUsuario });
    retorno = await this.pessoaService.salvar(pessoaUsuario);
    pessoaUsuario = retorno.objeto;
    this.util.salvarLocalStorage('PESSOA_USUARIO', pessoaUsuario);
    this.setState({ imagemCarregando: false, pessoaUsuario: pessoaUsuario });
    this.toast.current.show([
      { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
    ]);
  }
  render() {
    return (
      <div style={{ display: "flex", justifyContent: 'center' }}>
        <Toast ref={this.toast} />
        <section>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div>
              {this.state.pessoaUsuario.foto ?
                <img
                  alt="Foto de perfil do usuário"
                  style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                  src={this.state.urlBase + this.state.pessoaUsuario.foto} /> : <img
                  alt="Icone de usuário"
                  style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                  src={'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'} />}
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            {this.state.imagemCarregando ? <Button
              disabled={this.state.imagemCarregando}
              loading={this.state.imagemCarregando}
              icon="pi pi-camera"
              label="Alterar imagem" />
              :
              <label
                htmlFor="imagem_item"
                style={{ padding: 10, borderRadius: 5, backgroundColor: 'var(--primary-color)', cursor: 'pointer', color: "white" }}
              >
                <i className="pi pi-camera"></i> Alterar imagem
              </label>
            }
            <input
              type="file" id="imagem_item" style={{ display: 'none' }}
              onChange={pEvento => this.alterarImagem(pEvento.target.files[0])} />
          </div>
        </section>
      </div>
    )
  }
}