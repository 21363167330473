import { Component } from "react";
import Util from '../../util/Util';
import Constante from '../../util/Constante';
import HeaderSistemaFornecedor from "./Components/HeaderSistemaFornecedor";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";


export default class FornecedorEstacao extends Component {
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base)

  state = {
    pessoaUsuario: new Pessoa(),
    carregando: false,
    listaEstacao: [],

    conteudoNome: '',
    conteudoDataInicial: undefined,
    conteudoDataFinal: undefined,
  }

  componentDidMount() {
    this.iniciar()
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO')
    this.listar(pessoaUsuario)
    console.log(pessoaUsuario);

    this.setState({
      pessoaUsuario: pessoaUsuario
    })
  }


  async listar(pPessoa) {
    let listaPesquisa = [];

    let pessoaUsuario = pPessoa
    if (!pPessoa) {
      pessoaUsuario = this.state.pessoaUsuario
    }

    let pesquisa = new Pesquisa()
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa()
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa()
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoFornecedor;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    if (this.state.conteudoNome) {
      pesquisa = new Pesquisa()
      pesquisa.campo = 'pessoa.nome';
      pesquisa.conteudo = this.state.conteudoNome;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaEstacao = retorno.objeto
    console.log(listaEstacao);

    this.setState({
      listaEstacao: listaEstacao
    })

  }


  render() {
    return <div>
      <HeaderSistemaFornecedor />

      <main style={{ width: "98%", margin: "20px auto" }}>

        <div
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
          }}
        >
          <h1 style={{ fontSize: 22, margin: 0 }}>Estação</h1>
          <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estação</h2>
        </div>

        {/* FILTRO */}
        <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

            <div>
              <label>Nome</label><br />
              <InputText
                onChange={e => this.setState({ conteudoNome: e.value })}
                style={{ width: "100%" }}
              />
            </div>

            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button label="Buscar" className="p-button-raised" onClick={() => this.listar()}
              loading={this.state.carregando}
            />
          </div>

        </div>

        {/* FIM FILTRO */}


        {/* TABELA */}

        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          value={this.state.listaEstacao}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
        >

          <Column
            header='Nome'
            field="nome"
            body={pPessoa => <a href={'/fornecedor/estacao-detalhe?id=' + pPessoa._seqPessoa}>{pPessoa.nome}</a>}
          />

          <Column
            header='Data cadastro'
            sortable
            body={item => this.util.formatarData(item.dataCadastro)}
          />
        </DataTable>

        {/* FIM DA TABELA */}

      </main>
    </div>
  }
}