import { Component } from "react";
import { Button } from "primereact/button";
import LogoSemFundo from '../../../assets/images/logo-sem-fundo-branco.png';
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";

export default class HeaderSistemaAdm extends Component {
  constante = new Constante();
  util = new Util();
  render() {
    return <header style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, backgroundColor: this.constante.corPrimaria, }}>

      <div style={{ height: 50, width: 100 }}>
        <img
          alt="Logo branca"
          src={LogoSemFundo}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </div>


      <nav style={{ display: 'flex', alignItems: "center", gap: 10, }}>
        <Button
          style={{ color: 'white' }}
          label="Clientes"
          className="p-button-link"
          onClick={() => window.location.href = '/admin/cliente'} />

        <Button
          style={{ color: 'white' }}
          label="Fornecedores"
          className="p-button-link"
          onClick={() => window.location.href = '/admin/fornecedor'} />

        <Button
          style={{ color: 'white' }}
          label="Fabricantes"
          className="p-button-link"
          onClick={() => window.location.href = '/admin/fabricante'} />

        <Button
          style={{ color: 'white' }}
          label="Equipamento"
          className="p-button-link"
          onClick={() => window.location.href = '/admin/equipamento'} />

        <Button
          style={{ color: 'white' }}
          label="Estação"
          className="p-button-link"
          onClick={() => window.location.href = '/admin/estacao'} />
        <Button
          style={{ color: 'white' }}
          label="Perfil"
          className="p-button-link"
          onClick={() => window.location.href = '/admin/perfil'} />

        <Button
          style={{ color: 'white' }}
          label="Sair"
          className="p-button-link"
          onClick={() => {
            this.util.removerLocalStorage("PESSOA_USUARIO");
            window.location.href = '/login';
          }} />
      </nav>
    </header>
  }
}