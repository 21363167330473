import { Component } from "react";
import Util from "../util/Util";
import Constante from "../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import HeaderSistemaCliente from "./cliente/Components/HeaderSistemaCliente";
const PlotlyRenderers = createPlotlyRenderers(Plot);
export default class Analise extends Component {

  util = new Util();
  constante = new Constante();
  itemService = new ItemService(this.constante.token, this.constante.url_base);
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  state = {
    pessoaUsuario: new Pessoa(),
    listaMovimentoEstoque: [],
    pivo: null,
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = this.util.buscarLocalStorage("PESSOA_USUARIO");

    let listaMovimentoEstoque = await this.listar(pessoaUsuario);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaMovimentoEstoque: listaMovimentoEstoque,
    });
  }

  async listar(pPessoaUsuario) {
    let pessoaUsuario = pPessoaUsuario;
    if (!pPessoaUsuario) {
      pessoaUsuario = this.state.pessoaUsuario;
    }
    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'movimento_estoque.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'movimento_estoque.seq_pessoa';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.util.listarComFiltro(listaPesquisa);
    let listaMovimentoEstoque = retorno.objeto;
    for (const movimento of listaMovimentoEstoque) {
      movimento.dataMovimento = this.util.formatarDataComHora(movimento.dataMovimento, movimento.horaMovimento)
    }
    listaMovimentoEstoque.sort((a, b) => a.dataMovimento - b.dataMovimento);
    listaMovimentoEstoque = await this.listaTela(listaMovimentoEstoque);
    console.log(listaMovimentoEstoque)
    this.setState({
      carregando: false,
      listaMovimentoEstoque: listaMovimentoEstoque
    });
    return listaMovimentoEstoque;
  }

  listaPorMes(pListaMovimento) {
    const grupos = pListaMovimento.reduce((contador, item) => {
      const [dia, mes, ano] = item.dataMovimento.split('-');
      const mesNome = new Date(`${ano.split('T')[0]}-${mes}-${dia}T00:00:00`).toLocaleString('default', { month: 'long' });
      if (!contador[mesNome]) {
        contador[mesNome] = { dataMovimento: mesNome, quantidade: 0 };
      }
      contador[mesNome].quantidade += item.quantidade;
      return contador;
    }, {});

    const novaLista = [];
    for (const key in grupos) {
      novaLista.push(grupos[key]);
    }
    this.setState({ listaMovimentoEstoque: novaLista });
    return novaLista;
  }

  listaTela(pListaMovimento) {
    let lista = pListaMovimento;
    let listaFinal = [];
    for (const movimento of lista) {
      let objeto = {};
      const [dia, mes, ano] = movimento.dataMovimento.toLocaleDateString('pt-br').split('/');
      objeto.data = movimento.dataMovimento.toLocaleDateString('pt-br');
      const mesNome = new Date(Number(ano), Number(mes) - 1, Number(dia)).toLocaleString('default', { month: 'long' });
      objeto.mes = mesNome;
      objeto.hora = movimento.horaMovimento;
      objeto.quantidade = movimento.quantidade;
      objeto.item = movimento._itemNome;
      objeto.estacao = movimento._cdNome;
      listaFinal.push(objeto);
    }
    return listaFinal;
  }
  render() {
    return (
      <div>
        <HeaderSistemaCliente />
        <main main style={{ width: "100%", margin: "20px auto" }}>
          <section style={{ width: '100vw', minHeight: 500, padding: 20, overflowX: 'scroll', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <PivotTableUI
              data={this.state.listaMovimentoEstoque}
              onChange={s => this.setState({ pivo: s })}
              renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
              {...this.state.pivo}
            />
          </section>
        </main >
      </div >
    )
  }
}