import { Component, createRef } from "react";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente";

export default class ClienteAnalista extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    listaAnalista: [],
    listaFiltro: [],
    carregando: false,
    dialogNovoAnalistaVisivel: false,
    inputError: {
      emailAnalista: false,
    },
    emailAnalista: '',
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaAnalista = await this.listar(pessoaUsuario);

    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaAnalista: listaAnalista,
    })
  }

  async listar(pPessoa) {
    let pessoaUsuario = pPessoa;
    if (!pPessoa) {
      pessoaUsuario = this.state.pessoaUsuario;
    }
    this.setState({ carregando: true });
    const listaPequisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoAnalista;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.situacao';
    pesquisa.conteudo = 'ATIVO';
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);


    if (this.state.filtro.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa_relacionada.nome';
      pesquisa.conteudo = this.state.filtro.nome;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPequisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa_relacionamento.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPequisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa_relacionamento.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPequisa.push(pesquisa);
    }


    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaAnalista = retorno.objeto;
    for (const analista of listaAnalista) {
      analista._pessoaRelacionamentoDataCadastro = this.util.formatarDataBancoParaData(analista._pessoaRelacionamentoDataCadastro);
    }

    this.setState({
      listaAnalista: listaAnalista,
      carregando: false,
    })
    return listaAnalista
  }


  async salvarNovoAnalista() {
    this.setState({ carregando: true })
    let pessoaUsuario = this.state.pessoaUsuario
    let pessoa = new Pessoa();
    let email = this.state.emailAnalista;
    let inputError = this.state.inputError
    let inputOk = true;

    Object.keys(inputError).forEach(key => {
      inputError[key] = false
    });

    if (!this.state.emailAnalista) {
      inputError.emailAnalista = true;
    }

    if (!this.util.validarEmail(email)) {
      inputError.emailAnalista = true;
    }

    Object.keys(inputError).forEach(key => {
      if (inputError[key]) {
        inputOk = false
      }
    })

    this.setState({ inputError: inputError });

    if (!inputOk) {
      this.setState({ carregando: false });
      return
    }

    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilAnalista;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.email_plataforma_lirida';
    pesquisa.conteudo = email;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    pessoa = retorno.objeto[0];
    console.log('pessoa', pessoa);
    if (!pessoa) {
      this.toast.current.show({ severity: 'error', summary: 'Não localizado.', detail: 'Verifique se você digitou o e-mail correto.' });
      this.setState({ carregando: false });
      return
    }

    retorno = await this.pessoaService.listarRelacionamento(pessoaUsuario);
    let listaRelacionamento = retorno.objeto;

    let relacionamentoAnalista;
    for (const relacionamento of listaRelacionamento) {
      if (relacionamento.seqPessoaRelacionada === pessoa._seqPessoa) {
        if (relacionamento.seqTipoRelacionamento === this.constante.seqTipoRelacionamentoAnalista) {
          if (relacionamento.situacao !== 'ATIVO') {
            relacionamento.situacao = 'ATIVO';
            relacionamentoAnalista = relacionamento
          }
        }
      }
    }

    if (relacionamentoAnalista) {
      retorno = await this.pessoaService.salvarRelacionamento(relacionamentoAnalista);
      this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Analista salvo com sucesso.' });

    } else {
      let relacionamento = new PessoaRelacionamento();
      relacionamento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
      relacionamento.seqPessoaRelacionada = pessoa._seqPessoa;
      relacionamento.seqUsuario = this.constante.seqUsuario;
      relacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoAnalista;
      relacionamento.situacao = 'ATIVO';
      retorno = await this.pessoaService.salvarRelacionamento(relacionamento);
      this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Analista salvo com sucesso.' });
    }

    window.location.href = ('/usuario/analista-detalhe?id=' + pessoa._seqPessoa)


    this.setState({
      carregando: false,
      dialogNovoAnalistaVisivel: false,
      email: '',
    })
  }

  render() {
    return <div>
      <HeaderSistemaCliente />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        {/* DIALOG NOVO ANALISTA */}
        <Dialog
          header="Adicionar novo analista"
          style={{ minWidth: 600 }}
          visible={this.state.dialogNovoAnalistaVisivel}
          onHide={() => this.setState({
            inputError: {
              ...this.state.inputError, emailAnalista: false
            },
            dialogNovoAnalistaVisivel: false,
            emailAnalista: '',
          })}>

          <form
            style={{ width: '100%', height: '100%' }}
            onSubmit={(pEvento) => { pEvento.preventDefault(); this.salvarNovoAnalista() }}>

            <label for='email-forms-novo-analista'>E-mail do Analista
              <InputText
                type="email"
                id="email-forms-novo-analista"
                style={{ width: "100%", borderColor: this.state.inputError.emailAnalista && 'red' }}
                onChange={pEvento => this.setState({ emailAnalista: pEvento.target.value })}
                value={this.state.emailAnalista || ''} />
              {this.state.inputError.emailAnalista && <small style={{ color: 'red' }}>E-mail inválido</small>}
            </label>

            <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: 20 }}>
              <Button
                type="submit"
                label="Adicionar" />
            </div>

          </form>

        </Dialog>

        <section
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            display: "flex",
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>Analistas</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Analistas</h2>
          </div>

          <div>
            <Button
              label='Novo'
              onClick={() => this.setState({ dialogNovoAnalistaVisivel: true, emailAnalista: '', })}
            />
          </div>
        </section>

        {/* FILTRO */}
        <form
          onSubmit={pEvento => {
            pEvento.preventDefault();
            this.listar();
          }}
          style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
            <div>
              <label>Nome</label><br />
              <InputText
                onChange={pTexto => this.setState({
                  filtro: {
                    ...this.state.filtro, nome: pTexto.target.value
                  }
                })}
                value={this.state.filtro.nome || ''}
                style={{ width: '100%', height: 40 }}
              />
            </div>


            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="submit"
              loading={this.state.carregando}
              label="Buscar"
              className="p-button-raised" style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </form>
        {/* FIM FILTRO */}


        {/* TABELA */}
        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
          value={this.state.listaAnalista}
          loading={this.state.carregando}
        >
          <Column header='Nome'
            field='nome'
            sortable
            body={pPessoa => <a target="_blank" rel="noreferrer" href={'/usuario/analista-detalhe?id=' + pPessoa._seqPessoaRelacionada}>{pPessoa._pessoaRelacionadaNome}</a>}
          />

          <Column header='Data cadastro'
            field='dataCadastro'
            sortable
            body={pPessoa => <p>{pPessoa._pessoaRelacionamentoDataCadastro.toLocaleDateString('pt-br')}</p>}
          />
        </DataTable>
        {/* FIM DA TABELA */}
      </main>
    </div >
  }
}