import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Login from './page/auth/Login';


import { locale, addLocale } from 'primereact/api';
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './index.css';

import RecuperarSenha from './page/auth/RecuperarSenha';
import ClientePerfil from './page/cliente/Cliente_Perfil';
import ClienteAnalista from './page/cliente/Cliente_Analista';
import ClienteAnalistaDetalhe from './page/cliente/Cliente_AnalistaDetalhe';
import ClienteEstacao from './page/cliente/Cliente_Estacao';
import ClienteEstacaoDetalhe from './page/cliente/Cliente_EstacaoDetalhe';
import ClienteEquipamento from './page/cliente/Cliente_Equipamento';
import ClienteEquipamentoDetalhe from './page/cliente/Cliente_EquipamentoDetalhe';

import AdmCliente from './page/administrador/Adm_Cliente';
import AdmClienteDetalhe from './page/administrador/Adm_ClienteDetalhe';
import AdmFornecedor from './page/administrador/Adm_Fornecedor';
import AdmFornecedorDetalhe from './page/administrador/Adm_FornecedorDetalhe';
import AdmFabricante from './page/administrador/Adm_Fabricante';
import AdmFabricanteDetalhe from './page/administrador/Adm_FabricanteDetalhe';
import AdmPerfil from './page/administrador/Adm_Perfil';
import AdmEquipamentoDetalhe from './page/administrador/Adm_EquipamentoDetalhe';
import AdmEquipamento from './page/administrador/Adm_Equipamento';
import AdmEstacao from './page/administrador/Adm_Estacao';
import AdmEstacaoDetalhe from './page/administrador/Adm_EstacaoDetalhe';

import FornecedorEquipamentos from './page/fornecedor/Fornecedor_Equipamentos';
import FornecedorCliente from './page/fornecedor/Fornecedor_cliente';
import FornecedorEstacao from './page/fornecedor/Fonecedor_estacao';
import FornecedorEstoque from './page/fornecedor/Fornecedor_estoque';
import FornecedorEstacaoDetalhe from './page/fornecedor/Fornecedor_estacaoDetalhe';
import FornecedorPerfil from './page/fornecedor/Fornecedor_perfil';

import FabricanteEquipamento from './page/fabricante/Fabricante_Equipamento';
import FabricanteEstacao from './page/fabricante/Fabricante_Estacao';
import FabricanteEstoque from './page/fabricante/Fabricante_Estoque';
import FabricanteFornecedor from './page/fabricante/Fabricante_Fornecedor';
import FabricantePerfil from './page/fabricante/Fabricante_Perfil';
import FabricanteEquipamentoDetalhe from './page/fabricante/Fabricante_EquipamentoDetalhe';
import FabricanteEstacaoDetalhe from './page/fabricante/Fabricante_EstacaoDetalhe';
import ClienteEstoque from './page/cliente/Cliente_Estoque';
import ClienteEstoqueDetalhe from './page/cliente/Cliente_EstoqueDetalhe';
import ClienteOrdemServico from './page/cliente/Cliente_OrdemServico';
import Analise from './page/Analise';
import PoliticaPrivacidade from './page/PoliticaPrivacidade';
import ConfiguracaoConta from './page/ConfiguracaoConta';
import ClienteOrdemServicoDetalhe from './page/cliente/Cliente_OrdemServicoDetalhe';
import AnaliseMobile from './page/AnaliseMobile';
import FornecedorOrdemServico from './page/fornecedor/Fornecedor_OrdemDeServico';
import FornecedorOrdemServicoDetalhe from './page/fornecedor/Fornecedor_OrdemDeServicoDetalhe';
import HotSite from './page/hotsite/hotsite';

addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar'
});

locale('br');

class Rota extends Component {
  render() {
    return window.location.pathname === this.props.path && this.props.component
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Rota path="/" component={<HotSite />} />
    <Rota path="/login" component={<Login />} />
    <Rota path="/recuperar_senha" component={<RecuperarSenha />} />
    <Rota path="/configuracao-conta" component={<ConfiguracaoConta />} />

    <Rota path="/admin/cliente" component={<AdmCliente />} />
    <Rota path="/admin/cliente-detalhe" component={<AdmClienteDetalhe />} />
    <Rota path="/admin/fornecedor" component={<AdmFornecedor />} />
    <Rota path="/admin/fornecedor-detalhe" component={<AdmFornecedorDetalhe />} />
    <Rota path="/admin/fabricante" component={<AdmFabricante />} />
    <Rota path="/admin/fabricante-detalhe" component={<AdmFabricanteDetalhe />} />
    <Rota path="/admin/equipamento" component={<AdmEquipamento />} />
    <Rota path="/admin/equipamento-detalhe" component={<AdmEquipamentoDetalhe />} />
    <Rota path="/admin/estacao" component={<AdmEstacao />} />
    <Rota path="/admin/estacao-detalhe" component={<AdmEstacaoDetalhe />} />
    <Rota path="/admin/perfil" component={<AdmPerfil />} />

    <Rota path="/fornecedor/cliente" component={<FornecedorCliente />} />
    <Rota path="/fornecedor/equipamento" component={<FornecedorEquipamentos />} />
    <Rota path="/fornecedor/estacao" component={<FornecedorEstacao />} />
    <Rota path="/fornecedor/ordem-de-servico" component={<FornecedorOrdemServico />} />

    <Rota path="/fornecedor/ordem-de-servico-detalhe" component={<FornecedorOrdemServicoDetalhe />} />
    <Rota path="/fornecedor/estoque" component={<FornecedorEstoque />} />
    <Rota path="/fornecedor/perfil" component={<FornecedorPerfil />} />
    <Rota path="/fornecedor/estacao-detalhe" component={<FornecedorEstacaoDetalhe />} />

    <Rota path="/fabricante/equipamento" component={<FabricanteEquipamento />} />
    <Rota path="/fabricante/equipamento-detalhe" component={<FabricanteEquipamentoDetalhe />} />
    <Rota path="/fabricante/estacao" component={<FabricanteEstacao />} />
    <Rota path="/fabricante/estacao-detalhe" component={<FabricanteEstacaoDetalhe />} />
    <Rota path="/fabricante/estoque" component={<FabricanteEstoque />} />
    <Rota path="/fabricante/fornecedor" component={<FabricanteFornecedor />} />
    <Rota path="/fabricante/perfil" component={<FabricantePerfil />} />



    <Rota path="/usuario/perfil" component={<ClientePerfil />} />
    <Rota path="/usuario/analista" component={<ClienteAnalista />} />
    <Rota path="/usuario/analista-detalhe" component={<ClienteAnalistaDetalhe />} />
    <Rota path="/usuario/equipamento" component={<ClienteEquipamento />} />
    <Rota path="/usuario/equipamento-detalhe" component={<ClienteEquipamentoDetalhe />} />
    <Rota path="/usuario/estacao" component={<ClienteEstacao />} />
    <Rota path="/usuario/estacao-detalhe" component={<ClienteEstacaoDetalhe />} />
    <Rota path="/usuario/estoque" component={<ClienteEstoque />} />
    <Rota path="/usuario/estoque-detalhe" component={<ClienteEstoqueDetalhe />} />
    <Rota path="/usuario/dashboard" component={<Analise />} />
    <Rota path="/usuario/analise-mobile" component={<AnaliseMobile />} />
    <Rota path="/usuario/ordem-de-servico" component={<ClienteOrdemServico />} />
    <Rota path="/usuario/ordem-de-servico-detalhe" component={<ClienteOrdemServicoDetalhe />} />

    <Rota path="/politica-privacidade" component={<PoliticaPrivacidade />} />



  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
