import { Component } from "react";
import { Button } from "primereact/button";
import LogoSemFundo from '../../../assets/images/logo-sem-fundo-branco.png';
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";

export default class HeaderSistemaCliente extends Component {
  constante = new Constante();
  util = new Util();
  render() {
    return <header style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, backgroundColor: this.constante.corPrimaria, }}>

      <div style={{ height: 50, width: 100 }}>
        <img
          alt="Logo branca"
          src={LogoSemFundo}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </div>


      <nav style={{ display: 'flex', alignItems: "center", gap: 10, }}>


        <Button
          style={{ color: 'white' }}
          label="Dashboard"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/dashboard'} />

        <Button
          style={{ color: 'white' }}
          label="Analistas"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/analista'} />


        <Button
          style={{ color: 'white' }}
          label="Estoque"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/estoque'} />

        <Button
          style={{ color: 'white' }}
          label="Estações"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/estacao'} />
        <Button
          style={{ color: 'white' }}
          label="Ordem de serviço"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/ordem-de-servico'} />

        <Button
          style={{ color: 'white' }}
          label="Equipamentos"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/equipamento'} />

        <Button
          style={{ color: 'white' }}
          label="Perfil"
          className="p-button-link"
          onClick={() => window.location.href = '/usuario/perfil'} />

        <Button
          style={{ color: 'white' }}
          label="Sair"
          className="p-button-link"
          onClick={() => {
            this.util.removerLocalStorage("PESSOA_USUARIO");
            window.location.href = '/login';
          }} />
      </nav>
    </header>
  }
}