import { Component, createRef } from "react";
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import TipoDocumentoProcesso from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso";
import TipoDocumentoProcessoService from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcessoService";
import DocumentoRelacionamento from "lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Toast } from "primereact/toast";

export default class ClienteOrdemServico extends Component {
  toast = createRef();
  constante = new Constante();
  util = new Util();
  documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  tipoDocumentoProcessoService = new TipoDocumentoProcessoService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaFornecedor: new Pessoa(),
    pessoaEstacao: new Pessoa(),
    documento: new Documento(),
    listaEstacao: [],
    listaFornecedor: [],
    listaDocumento: [],
    listaDocumentoProcesso: [],
    vDialogNovoDocumento: false,
    salvarCarregando: false,
    listaCarregando: false,
    filtro: {
      tipoDocumentoProcesso: new TipoDocumentoProcesso(),
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    },
    inputError: {
      estacao: false,
      fornecedor: false,
      descricao: false,
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    const listaDocumento = await this.listarDocumento(pessoaUsuario);
    const listaEstacao = await this.listarEstacao(pessoaUsuario);
    const listaFornecedor = await this.listarFornecedor();
    const listaDocumentoProcesso = await this.listarDocumentoProcesso();
    this.listarDocumentoProcesso();
    this.setState({
      listaDocumento: listaDocumento,
      pessoaUsuario: pessoaUsuario,
      listaFornecedor: listaFornecedor,
      listaEstacao: listaEstacao,
      listaDocumentoProcesso: listaDocumentoProcesso,
    })
  }

  async listarDocumento(pPessoaUsuario) {
    this.setState({ listaCarregando: true })
    let pessoaUsuario = pPessoaUsuario;
    if (!pPessoaUsuario) {
      pessoaUsuario = this.state.pessoaUsuario;
    }
    const listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = "documento.seq_usuario";
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = "documento.seq_pessoa";
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    if (this.state.filtro.tipoDocumentoProcesso?._seqTipoDocumentoProcesso) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "documento.seq_tipo_documento_processo";
      pesquisa.conteudo = this.state.filtro.tipoDocumentoProcesso._seqTipoDocumentoProcesso;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "documento.data_cadastro";
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro?.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "documento.data_cadastro";
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro?.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
    let listaDocumento = retorno.objeto;

    for (let i = 0; i < listaDocumento.length; i++) {
      listaDocumento[i].dataCadastro = this.util.formatarDataBancoParaData(listaDocumento[i].dataCadastro)
    }

    this.setState({
      listaDocumento: listaDocumento,
      listaCarregando: false,
    })
    return listaDocumento;
  }

  async listarEstacao(pPessoaUsuario) {
    const listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilEstacao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_dono';
    pesquisa.conteudo = pPessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaEstacao = retorno.objeto;
    return listaEstacao;
  }

  async listarFornecedor() {
    const listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilFornecedor;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaFornecedor = retorno.objeto;

    this.setState({
      listaFornecedor: listaFornecedor,
    })
    return listaFornecedor;
  }

  async listarDocumentoProcesso() {
    let retorno = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcesso(this.constante.seqTipoDocumentoOrdemDeServico);
    let listaDocumentoProcesso = retorno.objeto;
    return listaDocumentoProcesso;
  }

  async salvar() {
    this.setState({ salvarCarregando: true });
    let inputsOk = true;
    let inputError = this.state.inputError;
    for (const key in inputError) {
      inputError[key] = false;
    }

    if (!this.state.pessoaFornecedor._seqPessoa) {
      inputError.fornecedor = true;
    }

    if (!this.state.pessoaEstacao._seqPessoa) {
      inputError.estacao = true;
    }

    if (!this.state.documento.observacao || this.state.documento.observacao.length <= 3) {
      inputError.descricao = true;
    }

    for (const key in inputError) {
      if (inputError[key]) {
        inputsOk = false
      }
    }

    this.setState({ inputError: inputError });
    if (!inputsOk) {
      this.setState({ inputError: inputError, salvarCarregando: false });
      return
    }

    const pessoaUsuario = this.state.pessoaUsuario;
    const pessoaFornecedor = this.state.pessoaFornecedor;
    const pessoaEstacao = this.state.pessoaEstacao;
    let documento = this.state.documento;

    documento.seqUsuario = this.constante.seqUsuario;
    documento.seqPessoa = pessoaUsuario._seqPessoa;
    documento.seqTipoDocumento = this.constante.seqTipoDocumentoOrdemDeServico;
    documento.seqTipoDocumentoProcesso = this.constante.seqDocumentoProcessoAberto;


    let retorno = await this.documentoService.salvar(documento);
    documento = retorno.objeto;
    let documentoRelacionamento = new DocumentoRelacionamento();
    documentoRelacionamento.seqUsuario = this.constante.seqUsuario;
    documentoRelacionamento.seqDocumento = documento._seqDocumento;
    documentoRelacionamento.seqPessoaRelacionada = pessoaFornecedor._seqPessoa;
    documentoRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoFornecedor;
    documentoRelacionamento.situacao = 'ATIVO';

    this.documentoService.salvarRelacionamento(documentoRelacionamento);


    documentoRelacionamento = new DocumentoRelacionamento();
    documentoRelacionamento.seqUsuario = this.constante.seqUsuario;
    documentoRelacionamento.seqDocumento = documento._seqDocumento;
    documentoRelacionamento.seqPessoaRelacionada = pessoaEstacao._seqPessoa;
    documentoRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEstacao;
    documentoRelacionamento.situacao = 'ATIVO';

    this.documentoService.salvarRelacionamento(documentoRelacionamento);

    this.toast.current.show({ severity: 'success', summary: 'Sucesso.', detail: 'Ordem de serviço gerada com sucesso.' });

    this.resetarDialog();
    this.setState({ salvarCarregando: false });
  }

  resetarDialog() {
    let inputError = this.state.inputError;
    for (const key in inputError) {
      inputError[key] = false;
    }
    this.setState({
      documento: new Documento(),
      pessoaEstacao: new Pessoa(),
      pessoaFornecedor: new Pessoa(),
      vDialogNovoDocumento: false,
      inputError: inputError,
    })
  }
  render() {
    return (
      <div>
        <Dialog
          header='Nova ordem de serviço'
          style={{ minWidth: '700px', minHeight: '250px', maxWidth: '700px' }}
          visible={this.state.vDialogNovoDocumento}
          onHide={() => this.resetarDialog()}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              this.salvar();
            }}
          >
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: 10 }}>
              <label style={{ display: 'block' }}>
                Estação*
                <Dropdown
                  options={this.state.listaEstacao}
                  filter
                  placeholder="Selecione"
                  optionLabel="nome"
                  value={this.state.pessoaEstacao || ''}
                  onChange={pEvento => this.setState({ pessoaEstacao: pEvento.target.value })}
                  style={{
                    width: '100%',
                    borderColor: this.state.inputError.estacao && 'red'
                  }}
                />
                {this.state.inputError.estacao && <small style={{ color: 'red' }}>Estação inválida</small>}
              </label>

              <label style={{ display: 'block', width: '100%' }}>
                Fornecedor*
                <Dropdown
                  options={this.state.listaFornecedor}
                  filter
                  placeholder="Selecione"
                  optionLabel="nome"
                  value={this.state.pessoaFornecedor || ''}
                  onChange={pEvento => this.setState({ pessoaFornecedor: pEvento.target.value })}
                  style={{
                    width: '100%',
                    borderColor: this.state.inputError.fornecedor && 'red'
                  }}
                />
                {this.state.inputError.fornecedor && <small style={{ color: 'red' }}>Fornecedor inválido</small>}
              </label>
            </div>

            <label style={{ display: 'block', width: '100%', marginTop: 10 }}>
              Descrição*
              <InputTextarea
                style={{ width: '100%', borderColor: this.state.inputError.descricao && 'red' }}
                autoResize
                onChange={pEvento => this.setState({
                  documento: {
                    ...this.state.documento, observacao: pEvento.target.value
                  }
                })}
                value={this.state.documento.observacao || ''}
              />
              {this.state.inputError.descricao && <small style={{ color: 'red' }}>Descrição inválida</small>}
            </label>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
            </div>
            <Button
              label="Salvar"
              type="submit"
            />
          </form>

        </Dialog>
        <HeaderSistemaCliente />
        <Toast
          ref={this.toast}
        />
        <main style={{ padding: 10, width: "100%", margin: "20px auto", }}>
          <section
            style={{
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
              display: "flex",
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Ordem de serviço</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Ordem de serviço</h2>
            </div>

            <div>
              <Button
                label='Novo'
                onClick={() => this.setState({ vDialogNovoDocumento: true })}
              />
            </div>
          </section>

          {/* FORMULARIO */}
          <form
            onSubmit={(pEvento) => {
              pEvento.preventDefault();
              this.listarDocumento();
            }}
            style={{
              border: '1px solid lightgray',
              padding: 10,
              borderRadius: 3,
              gap: 20,
              marginTop: 20
            }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
              <div>
                <label>Processo</label><br />
                <Dropdown
                  options={[{ nome: 'Selecione', value: new TipoDocumentoProcesso() }, ...this.state.listaDocumentoProcesso]}
                  optionLabel="nome"
                  placeholder="Selecione"
                  onChange={pEvento => this.setState({
                    filtro: {
                      ...this.state.filtro, tipoDocumentoProcesso: pEvento.target.value
                    }
                  })}
                  value={this.state.filtro.tipoDocumentoProcesso || ''}
                  style={{ width: '100%', height: 40 }}
                />

              </div>

              <div>
                <label>Data cadastro inicial</label><br />
                <Calendar
                  onChange={(pEvento) => {
                    this.setState({
                      filtro: {
                        ...this.state.filtro, dataCadastroInicial: pEvento.value
                      }
                    })
                  }}
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>

              <div>
                <label>Data cadastro final</label><br />
                <Calendar
                  onChange={(pEvento) => {
                    this.setState({
                      filtro: {
                        ...this.state.filtro, dataCadastroFinal: pEvento.value
                      }
                    })
                  }}
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: 10 }}>
              <Button
                label="Buscar"
                type="submit"
                loading={this.state.listaCarregando}
              />
            </div>
          </form>

          <DataTable
            showGridlines
            removableSort
            paginator
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[50, 60, 70, 80, 100]}
            style={{ width: "100%", marginTop: 30 }}
            value={this.state.listaDocumento}
            loading={this.state.listaCarregando}
          >
            <Column
              header='Nome'
              field="_tipoDocumentoNome"
              body={pItem => <a href={`/usuario/ordem-de-servico-detalhe?id=${pItem._seqDocumento}`} target="_blank" rel="noreferrer">{pItem._tipoDocumentoNome}</a>}
              sortable
            />
            <Column
              header='Processo'
              field="_tipoDocumentoProcessoNome"
              sortable
            />


            <Column
              header='Data cadastro'
              field="dataCadastro"
              sortable
              body={pItem => <p>{pItem.dataCadastro.toLocaleString('pt-br').split(',')[0]}</p>}
            />
          </DataTable>
        </main>
      </div >
    )
  }
}