import { Component } from "react";
import { Chart } from 'primereact/chart';
import Constante from "../util/Constante";
import Util from "../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Item from "lirida-back-service/Servicos/Item/Item";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

export default class AnaliseMobile extends Component {
  constante = new Constante();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  itemService = new ItemService(this.constante.token, this.constante.url_base);

  state = {
    pessoaCliente: new Pessoa(),
    pessoaEstacao: new Pessoa(),
    pessoaEquipamento: new Pessoa(),
    item: new Item(),
    listaCarregando: false,
    listaMovimentoEstoque: [],
    filtro: {
      dataInicial: '',
      dataFinal: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let parametro = await this.util.buscarParametros();
    let pessoaCliente = new Pessoa();
    let pessoaEstacao = new Pessoa();
    let pessoaEquipamento = new Pessoa();
    let item = new Item();

    if (parametro?.idEmpresa) {
      let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.idEmpresa, this.constante.seqUsuario);
      pessoaCliente = retorno.objeto;
    }

    if (parametro?.idEstacao) {
      let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.idEstacao, this.constante.seqUsuario);
      pessoaEstacao = retorno.objeto;
    }

    if (parametro?.idEquipamento) {
      let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.idEquipamento, this.constante.seqUsuario);
      pessoaEquipamento = retorno.objeto;
    }

    if (parametro?.idItem) {
      let retorno = await this.itemService.buscar(parametro.idItem);
      item = retorno.objeto;
    }

    let listaMovimentoEstoque = await this.listarMovimentoEstoque(pessoaCliente, pessoaEstacao, pessoaEquipamento, item);

    this.setState({
      pessoaCliente: pessoaCliente,
      pessoaEstacao: pessoaEstacao,
      pessoaEquipamento: pessoaEquipamento,
      listaMovimentoEstoque: listaMovimentoEstoque,
      item: item,
    });
  }


  async listarMovimentoEstoque(pPessoaCliente, pPessoaEstacao, pPessoaEquipamento, pItem) {
    this.setState({ listaCarregando: true })
    let pessoaCliente = this.state.pessoaCliente;
    let pessoaEstacao = this.state.pessoaEstacao;
    let pessoaEquipamento = this.state.pessoaEquipamento;
    let item = this.state.item;
    if (pPessoaCliente) {
      pessoaCliente = pPessoaCliente;
    }
    if (pPessoaEstacao) {
      pessoaEstacao = pPessoaEstacao;
    }
    if (pPessoaEquipamento) {
      pessoaEquipamento = pPessoaEquipamento;
    }
    if (pItem) {
      item = pItem;
    }
    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'movimento_estoque.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    if (pessoaCliente?._seqPessoa) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.seq_pessoa';
      pesquisa.conteudo = pessoaCliente._seqPessoa;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (pessoaEstacao?._seqPessoa) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.seq_pessoa_cd';
      pesquisa.conteudo = pessoaEstacao._seqPessoa;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (pessoaEquipamento?._seqPessoa) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.tags2';
      pesquisa.conteudo = pessoaEquipamento._seqPessoa;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (item?._seqItem) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.seq_item';
      pesquisa.conteudo = item._seqItem;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.data_movimento';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.data_movimento';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.util.listarComFiltro(listaPesquisa);
    let listaMovimentoEstoque = retorno.objeto;
    for (const movimentoEstoque of listaMovimentoEstoque) {
      movimentoEstoque.dataMovimento = await this.formatarDataComHora(movimentoEstoque.dataMovimento, movimentoEstoque.horaMovimento)
    }

    listaMovimentoEstoque.sort((a, b) => a.dataMovimento - b.dataMovimento);

    this.setState({
      listaMovimentoEstoque: listaMovimentoEstoque,
      listaCarregando: false,
    });
    return listaMovimentoEstoque;
  }


  formatarDataComHora(pData, pHora) {
    const data = pData.split("T")[0]
    const [dia, mes, ano] = data.split('-');
    const [hora, minuto] = pHora.split(':');
    let datafinal = new Date(Number(ano), Number(mes) - 1, Number(dia), Number(hora), Number(minuto));
    return datafinal
  }

  render() {
    return (
      <div>
        <main main style={{
          width: '100%',
        }}>
          <div style={{ display: 'inline-block', justifyContent: 'center', minWidth: 750, padding: 10 }}>
            <form
              onSubmit={pEvento => {
                pEvento.preventDefault();
                this.listarMovimentoEstoque();
              }}
              style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20, width: '100%' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
                <div>
                  <label>Data inicial</label><br />
                  <Calendar
                    onChange={(pEvento) => {
                      this.setState({
                        filtro: {
                          ...this.state.filtro, dataInicial: pEvento.value
                        }
                      })
                    }}
                    showButtonBar
                    style={{ width: "100%", height: 40 }}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                  />
                </div>

                <div>
                  <label>Data final</label><br />
                  <Calendar
                    onChange={(pEvento) => {
                      this.setState({
                        filtro: {
                          ...this.state.filtro, dataFinal: pEvento.value
                        }
                      })
                    }}
                    showButtonBar
                    style={{ width: "100%", height: 40 }}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>

              <Button
                style={{ marginTop: 10 }}
                label="Enviar"
                type="submit"
              />
            </form>


            <div style={{
              marginTop: 20,
              minWidth: 700,
              overflowX: 'scroll'
            }}>
              <Chart
                type="line"
                data={{
                  labels: this.state?.listaMovimentoEstoque.map((item) => `${item.dataMovimento.toLocaleDateString('pt-br')} ${item.horaMovimento}`),
                  datasets: [{
                    label: `${this.state.item.codigoVitrine}`,
                    data: this.state.listaMovimentoEstoque.map((item) => item.quantidade),
                    borderColor: '#4bc0c0',
                    fill: false,
                    tesion: .4
                  }]
                }}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: this.state.item.codigoVitrine,
                      font: {
                        size: 16
                      }
                    }
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  }
                }} />
            </div>
          </div>
        </main>
      </div>
    )
  }
}