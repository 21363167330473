import { Component } from "react";
import Util from "../util/Util";

export default class FotoPessoaPaginaDetalhe extends Component {
  util = new Util();
  state = {
    urlBase: '',
  }
  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let urlBase = await this.util.buscarUrlBaseArquivo();
    this.setState({ urlBase: urlBase });
  }
  render() {
    return <>
      <div style={{ width: 150, height: 150, }}>
        {this.props?.foto ?
          <img
            alt="Foto de perfil do usuário"
            style={{ width: 150, height: 150, objectFit: 'contain' }}
            src={this.state.urlBase + this.props.foto} />
          :
          <img
            alt="Imagem placeholder para quando não houver imagem de perfil"
            style={{
              width: 150,
              height: 150,
              objectFit: 'cover'
            }}
            src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640"
          />
        }
      </div>
    </>
  }
}