import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
// import { TabView, TabPanel } from 'primereact/tabview';
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import HeaderSistemaFabricante from "./Components/HeaderSistemaFabricante";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import CepService from "lirida-back-service/Servicos/Cep/CepService";

export default class FabricanteEstacaoDetalhe extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  cepService = new CepService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEstacao: new Pessoa(),
    pessoaFornecedor: new Pessoa(),
    endereco: new Endereco(),
    listaFornecedor: [],
    listaRelacionamentoFornecedor: [],
    salvarCarregando: false,
    salvarFornecedorCarregando: false,
    inputError: {
      nome: false,
      tipoEstacao: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let pessoaEstacao = new Pessoa();
    let endereco = new Endereco();
    let parametro = await this.util.buscarParametros();
    let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id, this.constante.seqUsuario);

    if (retorno?.objeto) {
      pessoaEstacao = retorno.objeto;
      retorno = await this.pessoaService.listarEndereco(pessoaEstacao);
      for (const pEndereco of retorno.objeto) {
        if (pEndereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
          endereco = pEndereco
        }
      }
    }

    let listaFornecedor = await this.listarFornecedor();
    let listaRelacionamentoFornecedor = await this.listarRelacionamentoFornecedor(pessoaEstacao);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaEstacao: pessoaEstacao,
      endereco: endereco,
      listaFornecedor: listaFornecedor,
      listaRelacionamentoFornecedor: listaRelacionamentoFornecedor,
    })
  }

  async listarFornecedor() {
    let listaPequisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilFornecedor;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaFornecedor = retorno.objeto;
    this.setState({
      listaFornecedor: listaFornecedor
    })

    return listaFornecedor;
  }

  async listarRelacionamentoFornecedor(pPessoaEstacao) {
    let pessoaEstacao = this.state.pessoaEstacao;
    if (pPessoaEstacao) {
      pessoaEstacao = pPessoaEstacao;
    }
    let listaPequisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaEstacao._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoFornecedor;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaRelacionamentoFornecedor = retorno.objeto;
    console.log('LISTA RELACIONAMENTO', listaRelacionamentoFornecedor);
    this.setState({
      listaRelacionamentoFornecedor: listaRelacionamentoFornecedor
    })

    return listaRelacionamentoFornecedor;
  }

  async salvarNovoFornecedor() {
    let listaRelacionamentoFornecedor = await this.state.listaRelacionamentoFornecedor;
    let pessoaEstacao = this.state.pessoaEstacao;
    let pessoaFornecedor = this.state.pessoaFornecedor;
    if (!pessoaFornecedor._seqPessoa) {
      return
    }
    this.setState({ salvarFornecedorCarregando: true });
    this.toast.current.clear();


    for (const relacionamento of listaRelacionamentoFornecedor) {
      if (relacionamento._seqPessoaRelacionada === pessoaFornecedor._seqPessoa) {
        this.toast.current.show({ severity: 'error', summary: 'Fornecedor já vinculado', detail: 'Esse fornecedor já foi adicionado.' });
        this.setState({ salvarFornecedorCarregando: false });
        return
      }
    }

    let relacionamento = new PessoaRelacionamento();
    relacionamento.seqUsuario = this.constante.seqUsuario;
    relacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoFornecedor;
    relacionamento.seqPessoa = pessoaEstacao._seqPessoa;
    relacionamento.seqPessoaRelacionada = pessoaFornecedor._seqPessoa;

    let retorno = await this.pessoaService.salvarRelacionamento(relacionamento);
    console.log(retorno.objeto);
    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Fornecedor adicionado com sucesso.' });

    this.listarRelacionamentoFornecedor(pessoaEstacao);
    this.setState({
      pessoaFornecedor: new Pessoa(),
      salvarFornecedorCarregando: false,
    })
  }

  async salvar() {
    this.setState({ salvarCarregando: true });
    let pessoaEstacao = this.state.pessoaEstacao;
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputsOk = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });

    if (!pessoaEstacao.nome) {
      inputError.nome = true;
    }

    if (!pessoaEstacao.tags0) {
      inputError.tipoEstacao = true;
    }

    if (!endereco.cep) {
      inputError.cep = true;
    }

    if (!endereco.logradouro) {
      inputError.logradouro = true;
    }

    if (!endereco.numero) {
      inputError.numero = true;
    }

    if (!endereco.bairro) {
      inputError.bairro = true;
    }

    if (!endereco.cidade) {
      inputError.cidade = true;
    }

    if (!endereco.uf) {
      inputError.uf = true;
    }

    Object.keys(inputError).forEach(pKey => {
      if (inputError[pKey]) {
        inputsOk = false
      }
    })

    this.setState({
      inputError: inputError,
    })

    if (!inputsOk) {
      this.setState({ salvarCarregando: false })
      return;
    }

    let retorno = await this.pessoaService.salvar(pessoaEstacao);
    pessoaEstacao = retorno.objeto;

    retorno = await this.pessoaService.salvarEndereco(endereco);
    endereco = retorno.objeto;

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Alterações salvas com sucesso' });

    this.setState({
      salvarCarregando: false,
      pessoaEstacao: pessoaEstacao,
      endereco: endereco,
    })
  }

  async buscarCep() {
    this.setState({ salvarCarregando: true, buscarCepCarregando: true });
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputOk = true;

    Object.keys(inputError).forEach(pKeys => {
      inputError[pKeys] = false
    })

    if (!endereco.cep) {
      inputError.cep = true
    }

    Object.keys(inputError).forEach(pKeys => {
      if (inputError[pKeys]) {
        inputOk = false
      }
    });

    this.setState({
      inputError: inputError,
      salvarCarregando: false,
      buscarCepCarregando: false
    })

    if (!inputOk) {
      return
    }

    let retorno = await this.cepService.buscarCep(endereco.cep);

    endereco.logradouro = retorno.logradouro;
    endereco.complemento = retorno.complemento;
    endereco.bairro = retorno.bairro;
    endereco.cidade = retorno.localidade;
    endereco.uf = retorno.uf;

    this.setState({
      endereco: endereco,
      buscarCepCarregando: false,
      salvarCarregando: false,
    })
  }

  render() {
    return (<div>
      <HeaderSistemaFabricante />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }}
              onClick={() => window.location.href = '/usuario/estacao'}
              icon='pi pi-arrow-left'
            />
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Estação detalhe</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estação detalhe</h2>
            </div>
          </div>
          <Button
            loading={this.state.salvarCarregando}
            label="Salvar"
            onClick={() => this.salvar()}
          />
        </div>

        <form
          onSubmit={e => {
            e.preventDefault();
          }}>

          <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
            <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
            <h3>Dados da estação</h3>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
            <div>
              <div style={{ width: 150, height: 150, border: '1px solid lightgray' }}>

              </div>
            </div>

            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, height: 150 }}>
              <div style={{ width: '100%' }}>
                <label>Nome</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                  onChange={pEvento => this.setState({
                    pessoaEstacao: {
                      ...this.state.pessoaEstacao,
                      nome: pEvento.target.value
                    }
                  })}
                  value={this.state.pessoaEstacao.nome || ''} />
                {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
              </div>

              <div>
                <label for='form-cadastro-estacao-nome'>
                  Tipo de estação
                </label><br />
                <Dropdown
                  optionLabel='nome'
                  placeholder="Selecione"
                  style={{ width: '100%', borderColor: this.state.inputError.tipoEstacao ? 'red' : '' }}
                  value={this.state.pessoaEstacao.tags0 || ''}
                  options={[{ nome: 'Fixa', value: 'Fixa' }, { nome: 'Móvel', value: 'Móvel' }]}
                  onChange={(pEvento) => this.setState({
                    pessoaEstacao: {
                      ...this.state.pessoaEstacao, tags0: pEvento.value
                    }
                  })}
                />
                {this.state.inputError.tipoEstacao && <small style={{ color: 'red' }}>Tipo estação inválido</small>}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
            <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
            <h3>Informação de endereço</h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr 1fr", gap: 20 }}>
              <div style={{ width: '100%' }}>
                <label>CEP</label><br />
                <div className="p-inputgroup">
                  <InputText
                    value={this.state.endereco?.cep || ''}
                    type="tel"
                    style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        cep: this.util.formatarCEP(pEvento.target.value)
                      }
                    })}
                  />
                  <Button
                    disabled={this.state.buscarCepCarregando}
                    loading={this.state.buscarCepCarregando}
                    style={{ backgroundColor: this.constante.corPrimaria, color: this.constante.corSecundaria, borderColor: this.constante.corPrimaria }}
                    icon="pi pi-search"
                    type="button"
                    onClick={() => this.buscarCep(this.state.endereco)}
                  />
                </div>
                {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Logradouro</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      logradouro: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.logradouro || ''} />
                {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Número</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      numero: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.numero || ''} />
                {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Complemento</label><br />
                <InputText
                  style={{ width: "100%" }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      complemento: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.complemento || ''} />
              </div>

              <div style={{ width: '100%' }}>
                <label>Bairro</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      bairro: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.bairro || ''} />
                {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Cidade</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      cidade: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.cidade || ''} />
                {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>UF</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      uf: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.uf || ''} />
                {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
              </div>
            </div>
          </div>
        </form>
        {/* 
        <div style={{ width: '100%', border: '1px solid lightgray', borderRadius: 3, marginTop: 20, gap: 60, padding: 10 }}>
          <TabView >
            <TabPanel header='Fornecedores'>
              <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20 }}>
                <div style={{ width: '20rem' }}>
                  <label>
                    Fornecedor
                  </label><br />
                  <Dropdown
                    filter
                    filterBy="nome"
                    optionLabel="nome"
                    placeholder="Selecione"
                    style={{ width: '100%' }}
                    options={this.state.listaFornecedor}
                    value={this.state.pessoaFornecedor}
                    onChange={(pEvento) => {
                      this.setState({
                        pessoaFornecedor: pEvento.value
                      })
                    }}
                  />
                </div>

                <Button
                  loading={this.state.salvarFornecedorCarregando}
                  label="Adicionar"
                  onClick={() => this.salvarNovoFornecedor()}
                />
              </div>

              <DataTable
                showGridlines
                removableSort
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rows={50}
                rowsPerPageOptions={[50, 60, 70, 80, 100]}
                style={{ width: "100%", marginTop: 30 }}
                value={this.state.listaRelacionamentoFornecedor}
                loading={this.state.carregando}
              >
                <Column
                  sortable
                  header='Nome'
                  field="_pessoaRelacionadaNome"
                />

                <Column
                  sortable
                  header='Perfil'
                  field="_perfilNome"
                />

                <Column
                  sortable
                  header='Data cadastro'
                  field="dataCadastro"
                  body={(pObjeto) => this.util.formatarData(pObjeto?.dataCadastro)}
                />
              </DataTable>
            </TabPanel>

            <TabPanel header='Equipamentos'>

            </TabPanel>
          </TabView>
        </div> */}
      </main >
    </div >)
  }
}