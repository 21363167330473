import { Component, createRef } from "react";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import HeaderSistemaAdm from "./components/HeaderSistemaAdm";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Toast } from "primereact/toast";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import FotoPessoaPaginaDetalhe from "../../components/FotoPessoaPgDetalhe";

export default class AdmClienteDetalhe extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  cepService = new CepService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaCliente: new Pessoa(),
    endereco: new Endereco(),
    urlBase: '',
    salvarCarregando: false,
    buscarCepCarregando: false,
    inputError: {
      nome: false,
      tipoDocumento: false,
      email: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    },
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let pessoaCliente = new Pessoa();
    let endereco = new Endereco();
    let parametro = await this.util.buscarParametros();
    let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id, this.constante.seqUsuario);
    if (retorno?.objeto) {
      pessoaCliente = retorno.objeto;
      retorno = await this.pessoaService.listarEndereco(pessoaCliente);
      for (const pEndereco of retorno.objeto) {
        if (pEndereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
          endereco = pEndereco
        }
      }
    }
    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaCliente: pessoaCliente,
      endereco: endereco,
    })
  }

  async salvar() {
    this.setState({ salvarCarregando: true });
    let pessoaCliente = this.state.pessoaCliente;
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputsOk = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });

    if (!pessoaCliente.nome) {
      inputError.nome = true;
    }

    if (!pessoaCliente.tipoDocumento) {
      inputError.tipoDocumento = true;
    }

    if (!pessoaCliente.documento) {
      inputError.documento = true;
    } else if (pessoaCliente.tipoDocumento === 'CPF' && pessoaCliente.documento.length < 14) {
      inputError.documento = true;
    } else if (pessoaCliente.tipoDocumento === 'CNPJ' && pessoaCliente.documento.length < 18) {
      inputError.documento = true;
    } else if (!await this.util.validarDocumento(pessoaCliente.documento)) {
      inputError.documento = true;
    }

    if (!pessoaCliente.emailPlataformaLirida) {
      inputError.email = true;
    }

    if (!this.util.validarEmail(pessoaCliente.emailPlataformaLirida)) {
      inputError.email = true;
    }

    if (!pessoaCliente.telefonePlataformaLirida) {
      inputError.telefone = true;
    }
    if (!endereco.cep) {
      inputError.cep = true;
    }

    if (!endereco.logradouro) {
      inputError.logradouro = true;
    }

    if (!endereco.numero) {
      inputError.numero = true;
    }

    if (!endereco.bairro) {
      inputError.bairro = true;
    }

    if (!endereco.cidade) {
      inputError.cidade = true;
    }

    if (!endereco.uf) {
      inputError.uf = true;
    }

    Object.keys(inputError).forEach(key => {
      if (inputError[key]) {
        inputsOk = false
      }
    })
    this.setState({
      inputError: inputError,
    })

    if (!inputsOk) {
      this.setState({ salvarCarregando: false })
      return;
    }

    let retorno = await this.pessoaService.salvar(pessoaCliente);
    pessoaCliente = retorno.objeto;

    retorno = await this.pessoaService.salvarEndereco(endereco);
    endereco = retorno.objeto;

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Contratante salvo com sucesso.' });

    this.setState({
      salvarCarregando: false,
      pessoaCliente: pessoaCliente,
      endereco: endereco,
    })
  }

  async buscarCep() {
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputOk = true;

    Object.keys(inputError).forEach(pKeys => {
      inputError[pKeys] = false
    })

    if (!endereco.cep) {
      inputError.cep = true
    }

    Object.keys(inputError).forEach(pKeys => {
      if (inputError[pKeys]) {
        inputOk = false
      }
    });

    this.setState({ inputError: inputError })

    if (!inputOk) {
      return
    }

    this.setState({ salvarCarregando: true, buscarCepCarregando: true });

    let retorno = await this.cepService.buscarCep(endereco.cep);

    endereco.logradouro = retorno.logradouro;
    endereco.complemento = retorno.complemento;
    endereco.bairro = retorno.bairro;
    endereco.cidade = retorno.localidade;
    endereco.uf = retorno.uf;

    this.setState({
      endereco: endereco,
      buscarCepCarregando: false,
      salvarCarregando: false,
    })
  }

  render() {
    return (<div>
      <HeaderSistemaAdm />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }}
              onClick={() => window.location.href = '/admin/cliente'}
              icon='pi pi-arrow-left'
            />
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Cliente detalhe</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Cliente detalhe</h2>
            </div>
          </div>
          <Button
            style={{ backgroundColor: this.constante.corPrimaria, bordercolor: this.constante.corPrimaria }}
            loading={this.state.salvarCarregando}
            label="Salvar"
            onClick={() => this.salvar()}
          />
        </div>

        <form
          onSubmit={e => {
            e.preventDefault();
          }}>

          <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
            <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
            <h3>Dados do cliente</h3>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
            <FotoPessoaPaginaDetalhe
              foto={this.state.pessoaCliente.foto}
            />

            {/* <div>
              {this.state.pessoaCliente?.foto ?
                <img
                  alt="Foto de perfil do usuário"
                  style={{ width: 150, height: 150, border: '1px solid lightgray', objectFit: 'contain' }}
                  src={this.state.urlBase + this.state.pessoaCliente.foto} />
                : <div style={{ width: 150, height: 150, border: '1px solid lightgray' }}></div>
              }
            </div> */}

            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, height: 150 }}>
              <div style={{ width: '100%' }}>
                <label>Nome</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                  onChange={pEvento => this.setState({
                    pessoaCliente: {
                      ...this.state.pessoaCliente,
                      nome: pEvento.target.value
                    }
                  })}
                  value={this.state.pessoaCliente.nome || ''} />
                {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
              </div>

              <div style={{ width: '100%' && 'red' }}>
                <label>Tipo de pessoa</label><br />
                <Dropdown
                  disabled
                  options={[
                    { label: 'Pessoa física', value: 'CPF' },
                    { label: 'Pessoa jurídica', value: 'CNPJ' },
                  ]}
                  onChange={pEvento => this.setState({
                    pessoaCliente: {
                      ...this.state.pessoaCliente,
                      tipoDocumento: pEvento.value
                    }
                  })}
                  style={{ width: "100%", borderColor: this.state.inputError.tipoDocumento && 'red' }}
                  value={this.state.pessoaCliente.tipoDocumento || ''} />
                {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de pessoa inválido</small>}
              </div>


              {this.state.pessoaCliente.tipoDocumento === 'CPF' &&
                <div style={{ width: '100%' }}>
                  <label>CPF</label><br />
                  <InputText
                    disabled
                    style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                    onChange={pEvento => this.setState({
                      pessoaCliente: {
                        ...this.state.pessoaCliente,
                        documento: this.util.formatarCPF(pEvento.target.value)
                      }
                    })}
                    value={this.state.pessoaCliente.documento || ''} />
                  {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                </div>}
              {this.state.pessoaCliente.tipoDocumento === 'CNPJ' &&
                <div style={{ width: '100%' }}>
                  <label>CNPJ</label><br />
                  <InputText
                    disabled
                    style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                    onChange={pEvento => this.setState({
                      pessoaCliente: {
                        ...this.state.pessoaCliente,
                        documento: this.util.formatarCNPJ(pEvento.target.value)
                      }
                    })}
                    value={this.state.pessoaCliente.documento || ''} />
                  {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                </div>}

              <div style={{ width: '100%' }}>
                <label>Email</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.email && 'red' }}
                  onChange={pEvento => this.setState({
                    pessoaCliente: {
                      ...this.state.pessoaCliente,
                      emailPlataformaLirida: pEvento.target.value
                    }
                  })}
                  value={this.state.pessoaCliente.emailPlataformaLirida || ''} />
                {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Telefone</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.telefone && 'red' }}
                  onChange={pEvento => this.setState({
                    pessoaCliente: {
                      ...this.state.pessoaCliente,
                      telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                    }
                  })}
                  value={this.state.pessoaCliente.telefonePlataformaLirida || ''} />
                {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
            <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
            <h3>Informação de endereço</h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr 1fr", gap: 20 }}>
              <div style={{ width: '100%' }}>
                <label>CEP</label><br />
                <div className="p-inputgroup">
                  <InputText
                    value={this.state.endereco?.cep || ''}
                    type="tel"
                    style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        cep: this.util.formatarCEP(pEvento.target.value)
                      }
                    })}
                  />
                  <Button
                    disabled={this.state.buscarCepCarregando}
                    loading={this.state.buscarCepCarregando}
                    style={{ backgroundColor: this.constante.corPrimaria, color: this.constante.corSecundaria, borderColor: this.constante.corPrimaria }}
                    icon="pi pi-search"
                    type="button"
                    onClick={() => this.buscarCep(this.state.endereco)}
                  />
                </div>
                {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Logradouro</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      logradouro: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.logradouro || ''} />
                {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Número</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      numero: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.numero || ''} />
                {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Complemento</label><br />
                <InputText
                  style={{ width: "100%" }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      complemento: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.complemento || ''} />
              </div>

              <div style={{ width: '100%' }}>
                <label>Bairro</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      bairro: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.bairro || ''} />
                {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>Cidade</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      cidade: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.cidade || ''} />
                {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
              </div>

              <div style={{ width: '100%' }}>
                <label>UF</label><br />
                <InputText
                  style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                  onChange={pEvento => this.setState({
                    endereco: {
                      ...this.state.endereco,
                      uf: pEvento.target.value
                    }
                  })}
                  value={this.state.endereco.uf || ''} />
                {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>)
  }
}