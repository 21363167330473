import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Component } from "react";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import HeaderSistemaAdm from "./components/HeaderSistemaAdm";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class AdmEstacaoDetalhe extends Component {
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEstacao: new Pessoa(),
    endereco: new Endereco(),
    listaFornecedor: [],
    listaEquipamento: [],
    salvarCarregando: false,
   
    inputError: {
      nome: false,
      tipoDocumento: false,
      email: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    },
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let pessoaEstacao = new Pessoa();
    let endereco = new Endereco();
    let parametro = await this.util.buscarParametros();
    let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id, this.constante.seqUsuario);
    if (retorno?.objeto) {
      pessoaEstacao = retorno.objeto;
      retorno = await this.pessoaService.listarEndereco(pessoaEstacao);
      for (const pEndereco of retorno.objeto) {
        if (pEndereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
          endereco = pEndereco
        }
      }
    }
    this.listarFornecedor(pessoaEstacao)
    this.listarEquipamento(pessoaEstacao)

    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaEstacao: pessoaEstacao,
      endereco: endereco,
    })
  }


  async listarFornecedor(pPessoa) {

    let pessoaEstacao = pPessoa
    if (!pPessoa) {
      pessoaEstacao = this.state.pessoaEstacao
    }

    let listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
    pesquisa.conteudo = pessoaEstacao._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoFornecedor;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaFornecedor = retorno.objeto;

    this.setState({
      listaFornecedor: listaFornecedor
    })

    return listaFornecedor;
  }

  async listarEquipamento(pPessoa) {

    let pessoaEstacao = pPessoa
    if (!pPessoa) {
      pessoaEstacao = this.state.pessoaEstacao
    }

    let listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_dono';
    pesquisa.conteudo = pessoaEstacao._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilEquipamento;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);


    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaEquipamento = retorno.objeto;
    
    this.setState({
      listaEquipamento: listaEquipamento
    })

    return listaEquipamento;
  }

  render() {
    return (<div>
      <HeaderSistemaAdm />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{ backgroundColor: this.constante.corPrimaria, borderColor: this.constante.corPrimaria }}
              onClick={() => window.location.href = '/admin/estacao'}
              icon='pi pi-arrow-left'
            />
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Estação detalhe</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estação detalhe</h2>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
          <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
          <h3>Dados da estação</h3>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
          <div>
            <div style={{ width: 150, height: 150, border: '1px solid lightgray' }}>

            </div>
          </div>

          <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, height: 150 }}>
            <div style={{ width: '100%' }}>
              <label>Nome</label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                onChange={pEvento => this.setState({
                  pessoaEstacao: {
                    ...this.state.pessoaEstacao,
                    nome: pEvento.target.value
                  }
                })}
                value={this.state.pessoaEstacao.nome || ''} />
              {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
            </div>

            <div>
              <label for='form-cadastro-estacao-nome'>
                Tipo de estação
              </label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                value={this.state.pessoaEstacao.tags0 || ''} />
              {this.state.inputError.tipoEstacao && <small style={{ color: 'red' }}>Tipo estação inválido</small>}
            </div>
          </div>
        </div>



        <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 20 }}>
          <i style={{ color: this.constante.corPrimaria, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corPrimaria}` }} className="pi pi-file" />
          <h3>Informação de endereço</h3>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 5, gap: 60 }}>
          <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr 1fr", gap: 20 }}>
            <div style={{ width: '100%' }}>
              <label>CEP</label><br />
              <div className="p-inputgroup">
                <InputText
                  disabled
                  value={this.state.endereco?.cep || ''}
                  type="tel"
                  style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                />
                <Button
                  disabled
                  loading={this.state.buscarCepCarregando}
                  style={{ backgroundColor: this.constante.corPrimaria, color: this.constante.corSecundaria, borderColor: this.constante.corPrimaria }}
                  icon="pi pi-search"
                  type="button"
                  onClick={() => this.buscarCep(this.state.endereco)}
                />
              </div>
              {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
            </div>

            <div style={{ width: '100%' }}>
              <label>Logradouro</label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                value={this.state.endereco.logradouro || ''} />
              {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
            </div>

            <div style={{ width: '100%' }}>
              <label>Número</label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                value={this.state.endereco.numero || ''} />
              {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
            </div>

            <div style={{ width: '100%' }}>
              <label>Complemento</label><br />
              <InputText
                disabled
                style={{ width: "100%" }}
                value={this.state.endereco.complemento || ''} />
            </div>

            <div style={{ width: '100%' }}>
              <label>Bairro</label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                value={this.state.endereco.bairro || ''} />
              {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
            </div>

            <div style={{ width: '100%' }}>
              <label>Cidade</label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                value={this.state.endereco.cidade || ''} />
              {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
            </div>

            <div style={{ width: '100%' }}>
              <label>UF</label><br />
              <InputText
                disabled
                style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                value={this.state.endereco.uf || ''} />
              {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
            </div>
          </div>
        </div>

        <div style={{ width: '100%', border: '1px solid lightgray', borderRadius: 3, marginTop: 20, gap: 60, padding: 10 }}>
          <TabView >
            <TabPanel header='Fornecedores'>

              <DataTable
                showGridlines
                removableSort
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rows={50}
                rowsPerPageOptions={[50, 60, 70, 80, 100]}
                style={{ width: "100%", marginTop: 30 }}
                value={this.state.listaFornecedor}
                loading={this.state.carregando}
              >
                <Column
                  sortable
                  header='Nome'
                  field="_pessoaRelacionadaNome"
                />

                <Column
                  sortable
                  header='Perfil'
                  field="_perfilNome"
                />

                <Column
                  sortable
                  header='Data cadastro'
                  field="dataCadastro"
                  body={(pObjeto) => this.util.formatarData(pObjeto?.dataCadastro)}
                />
              </DataTable>
            </TabPanel>

            <TabPanel header='Equipamentos'>

              <DataTable
                showGridlines
                removableSort
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rows={50}
                rowsPerPageOptions={[50, 60, 70, 80, 100]}
                style={{ width: "100%", marginTop: 30 }}
                value={this.state.listaEquipamento}
                loading={this.state.carregando}
              >
                <Column
                  sortable
                  header='Nome'
                  field="nome"
                />

                <Column
                  sortable
                  header='Perfil'
                  field="_perfilNome"
                />

                <Column
                  sortable
                  header='Data cadastro'
                  field="dataCadastro"
                  body={(pObjeto) => this.util.formatarData(pObjeto?.dataCadastro)}
                />
              </DataTable>

            </TabPanel>
          </TabView>
        </div>
      </main>
    </div>)
  }
}