import { Component } from "react";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import HeaderSistemaCliente from "./Components/HeaderSistemaCliente";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import ItemService from "lirida-back-service/Servicos/Item/ItemService"
import Item from "lirida-back-service/Servicos/Item/Item"

export default class ClienteEstoque extends Component {
  constante = new Constante();
  util = new Util();
  itemService = new ItemService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    listaEstoque: [],
    listaItem: [],
    carregando: false,
    filtro: {
      nome: new Item(),
      horaMovimento: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
      dataMovimentoInicial: '',
      dataMovimentoFinal: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

    let listaEstoque = await this.listar(pessoaUsuario);
    this.listarItem()
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaEstoque: listaEstoque,
    })
  }

  async listar(pPessoaUsuario) {
    this.setState({ carregando: true });
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    }

    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'movimento_estoque.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'movimento_estoque.seq_pessoa';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    if (this.state.filtro?.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.seq_item';
      pesquisa.conteudo = this.state.filtro.nome._seqItem;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    // if (this.state.filtro?.horaMovimento) {
    //   pesquisa = new Pesquisa();
    //   pesquisa.campo = 'movimento_estoque.hora_movimento';
    //   pesquisa.conteudo = this.state.filtro.horaMovimento;
    //   pesquisa.operacao = 'AND';
    //   pesquisa.tipo = 'TEXTO';
    //   listaPesquisa.push(pesquisa);
    // }

    if (this.state.filtro?.dataMovimentoInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.data_movimento';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataMovimentoInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataMovimentoFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.data_movimento';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataMovimentoFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }


    if (this.state.filtro?.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'movimento_estoque.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.listarComFiltro(listaPesquisa);
    let listaEstoque = retorno.objeto;
    console.log(listaEstoque);

    this.setState({ carregando: false, listaEstoque: listaEstoque });
    return listaEstoque;
  }

  async listarComFiltro(pListaPesquisa) {
    let cabecalho = new Headers();
    cabecalho.append("token", this.constante.token);
    cabecalho.append("Content-Type", "application/json");

    let res = await fetch(this.constante.url_base + "estoque/listar_com_filtro", {
      method: 'POST',
      headers: cabecalho,
      redirect: 'follow',
      body: JSON.stringify(pListaPesquisa)
    })
    res = await res.json()
    return res;
  }

  async listarItem() {

    let retorno = await this.itemService.listar(this.constante.seqUsuario, '')
    let listaItem = retorno.objeto
    console.log('listaItem', listaItem);
    this.setState({ listaItem: listaItem })

    new Item()
  }

  render() {
    return <div>
      <HeaderSistemaCliente />
      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />

        <div
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            display: "flex",
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>Estoque</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Estoque</h2>
          </div>
        </div>

        {/* FILTRO */}
        <form
          onSubmit={pEvento => {
            pEvento.preventDefault();
            this.listar();
          }}
          style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
            <div>
              <label>Nome</label><br />
              <Dropdown
                onChange={pEvento => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pEvento.target.value
                    }
                  })
                }}
                options={[{ nomeVitrine: 'Todos' }, ...this.state.listaItem]}
                optionLabel={'nomeVitrine'}
                value={this.state.filtro.nome || ''}
                placeholder="Selecione"
                style={{ width: '100%', height: 40 }}
              />
            </div>

            {/* <div>
              <label>Hora movimento</label><br />
              <InputText
                onChange={pEvento => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, horaMovimento: pEvento.target.value
                    }
                  })
                }}
                type='time'
                maxLength={5}
                value={this.state.filtro.horaMovimento || ''}
                placeholder="Nome"
                style={{ width: '100%', height: 40 }}
              />
            </div> */}


            <div>
              <label>Data movimento inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataMovimentoInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data movimento final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataMovimentoFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="submit"
              loading={this.state.carregando}
              label="Buscar"
              className="p-button-raised" style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </form>
        {/* FIM FILTRO */}


        {/* TABELA */}
        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
          value={this.state.listaEstoque}
          loading={this.state.carregando}
        >
          <Column header='Nome'
            field='nome'
            sortable
            body={item => <a target="_blank" rel="noreferrer" href={'/usuario/estoque-detalhe?id=' + item._seqPessoaRelacionada}>{item._itemNome}</a>}
          />

          <Column header='Estação'
            field='_cdNome'
            sortable
          />

          <Column header='Hora movimento'
            field='horaMovimento'
            sortable
          />

          <Column header='Data movimento'
            field='datamovimento'
            sortable
            body={item => this.util.formatarData(item.dataMovimento)}
          />


          <Column header='Data cadastro'
            field='dataCadastro'
            sortable
            body={item => this.util.formatarData(item.dataCadastro)}
          />
        </DataTable>
        {/* FIM DA TABELA */}
      </main>
    </div>
  }
}