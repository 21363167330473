import { Component } from "react";
import './style.css';
import Logo from '../../assets/images/logoHotsite.png';
import Imagem1 from '../../assets/images/imagem1.png';
import Imagem2 from "../../assets/images/imagem2.png";
import Imagem3 from "../../assets/images/imagem3.png";
import Imagem4 from "../../assets/images/imagem4.png";
import Imagem5 from "../../assets/images/imagem5.png";
import Imagem6 from "../../assets/images/imagem6.png";
import Imagem7 from "../../assets/images/imagem7.png";
import Imagem8 from "../../assets/images/imagem8.png";
import Imagem9 from "../../assets/images/imagem9.png";
import Imagem10 from "../../assets/images/imagem10.png";

export default class HotSite extends Component {

  render() {
    return (
      <div className="secaoMain">
        <header className="container-header-hotsite">
          <nav className="nav-header">
            <a
              href="/"
            >
              <img
                className='logo-header' alt="Logo Ecovision" src={Logo} />
            </a>
            <ul className="lista-link-header">
              <a className="link-header" href="/#quem_somos" >Quem Somos</a>
              <a className="link-header" href="/#sobre">Sobre</a>
              <a className="link-header" href="/#funcionalidades">Funcionalidades</a>
              {/* <a className="link-header" href="/">Depoimentos</a> */}
              <a className="botao-verde-link" href="/login">Login</a>
            </ul>
          </nav>
        </header>

        <main className="main-hotsite">
          <section className="secao">
            <div className="containerVerde">
              <div className="container-conteudo">
                <div className="container-texto">
                  <h1 className="titulo-card">Transforme dados complexos em insights claros</h1>
                  <p className="texto-card">Não perca mais tempo tentando analisar dados complicados. Vizualize os dados de uma forma clara e fácil</p>

                  {/* <div className="container-botao">
                    <a className="botao-cadastrese" href="/">
                      Cadastre-se
                    </a>

                    <a className="botao-saibamais" href="/">
                      Saiba Mais
                    </a>
                  </div> */}
                </div>
              </div>

              <div className="container-imagem">
                <img
                  alt="Imagem de um tablet com bi aberto e uma mão selecionando"
                  className="imagem1"
                  src={Imagem1} />
              </div>
            </div>
          </section>

          <section
            id="quem_somos"
            className="secao">
            <div className="wrapper">
              <div className="container-conteudo-section2">
                <div className="container-texto-section2">
                  <h2 className="titulo-card-verde">Quem Somos</h2>
                  <p className="texto-card-preto">Nós somos uma empresa líder em soluções de monitoramento de ar que acredita que a qualidade do ar é fundamental para a saúde e o bem-estar das pessoas e do planeta. Nós desenvolvemos um software inovador que torna mais fácil para os analistas ambientais monitorarem e analisarem dados de qualidade do ar, garantindo que as empresas e comunidades possam tomar decisões informadas sobre a saúde ambiental.</p>
                </div>
              </div>

              <div className="container-imagem">
                <img
                  alt='Imagem de gráfico de linha e com nuvens azuis ao redor'
                  className="imagem2"
                  src={Imagem2} />
              </div>
            </div>
          </section>


          <section
            id="sobre"
            className="secao">
            <div className="containerVerde">
              <div className="container-section3-imagem">
                <img
                  className="imagem3"
                  alt='Imagem de um aparelho que coleta dados da atmosfera'
                  src={Imagem3}
                />
              </div>

              <div className="container-section3-texto">

                <div className="container-conteudo-section3">
                  <h2 className="titulo-secao3">Sobre</h2>
                  <p className="texto-secao3">
                    Nós nos dedicamos a fornecer aos nossos clientes a tecnologia mais avançada e eficiente para monitorar a qualidade ambienteal. Nós nos esforçamos para tornar a análise de dados de qualidade do ar fácil e acessível para todas as empresas e comunidades, independentemente do tamanho ou da complexidade do projeto.
                  </p>
                </div>

              </div>
            </div>
          </section>

          <section
            id="funcionalidades"
            className="secao">
            <div className="wrapper">
              <div className="container-texto-secao4">
                <h2 className="titulo-card-secao4">Monitorar e Analisar dados precisos e <span>em tempo real.</span></h2>
                <p className="texto-card-secao4">
                  Nosso software funcionalidades avançadas para ajudar os analistas ambientais a monitorar e analisar dados precisos e em tempo real.
                </p>
                <div>
                  {/* <a className="botao-verde-link" href="/">Saiba mais</a> */}
                </div>
              </div>

              <div className="container-imagem-secao4">
                <img
                  className="imagem4"
                  src={Imagem4} alt="Imagem de um gráfico de barra" />
              </div>
            </div>
          </section>


          <section
            className="secao">
            <div className="wrapper">
              <div className="container-imagem-secao5">
                <img
                  className="imagem5"
                  src={Imagem5} alt="Imagem de check verde" />
              </div>

              <div className="container-texto-secao5">
                <h2 className="titulo-card-secao5">Visualizar dados complexos em um<span> formato fácil de entender.</span></h2>
                <p className="texto-card-secao5">
                  Com nossos dashboards claros e bonitos, você pode facilmente visualizar dados complexos em um formato fácil de entender, permitindo que você tome decisões mais  rapidamente.
                </p>
                <div>
                  {/* <a className="botao-verde-link" href="/">Saiba mais</a> */}
                </div>
              </div>
            </div>
          </section>

          {/* SECAO 6 */}
          <section
            className="secao">
            <div className="wrapper">
              <div className="container-texto-secao6">
                <h2 className="titulo-card-secao6">Totalmente adaptável ao seu <span>estilo de trabalho.</span></h2>
                <p className="texto-card-secao6">
                  Além disso, nosso software é altamente personalizável e pode ser adaptado para atender às necessidades específicas do seu projeto.
                </p>
                <div>
                  <a className="botao-verde-link" href="/#contato">Entre em contato</a>
                </div>
              </div>

              <div className="container-imagem-secao6">
                <img
                  className="imagem6"
                  src={Imagem6} alt="Imagem planeta terra" />
              </div>
            </div>
          </section>
          {/* ************** */}


          {/* SECAO 7 */}
          <section
            className="secao"
          >
            <div className="containerVerde">
              <div className="wrapper">
                <div className="container-texto-secao7">
                  <h2 className="titulo-card-secao7">
                    Fornecendo tecnologia avançada e eficiente, tornando a análise de dados acessível e clara a todas os profissionais ambientais.
                  </h2>
                </div>

                <div className="container-imagem7">
                  <img
                    alt="Imagem de uma mão segurando um smartphone"
                    className="imagem7"
                    src={Imagem7} />
                </div>
              </div>
            </div>
          </section>
          {/* ************** */}


          {/* SECAO 8  */}
          <section
            className="secao"
          >
            <div className="wrapper-container-secao8">

              <div className="container-conteudo-secao8">
                <div className="wrapper-conteudo-secao8">
                  <div>
                    <h2 className="titulo-principal-card-secao8">Recursos</h2>
                    <p className="texto-secao8">Conheça nossos recursos técnicos que vão te auxiliar na análise profunda.</p>
                  </div>
                </div>
              </div>

              <div className="container-conteudo-secao8">
                <div className="container-imagem-secao8">
                  <img
                    className="imagem8"
                    src={Imagem8}
                    alt="Imagem do ar"
                  />
                </div>
                <h2 className="titulo-card-secao8">Análise de <span>índice de qualidade do Ar</span></h2>
                <p className="texto2-secao8">Temperatura, umidade, pressão atmosférica, direção do vento, rajada, etc...</p>
              </div>

              <div className="container-conteudo-secao8">
                <div className="container-imagem-secao8">
                  <img
                    className="imagem9"
                    src={Imagem9}
                    alt="Imagem de duas gotas de água"
                  />
                </div>
                <h2 className="titulo-card-secao8">Análise de <span>índice de qualidade da Água</span></h2>
                <p className="texto2-secao8">Temperatura, umidade, pressão atmosférica, direção do vento, rajada, etc...</p>
              </div>
            </div>
          </section>
          {/* ************** */}
        </main>


        <footer className="footer-hotsite">
          <div className="wrapper-footer">
            <div className="container-logo-footer">
              <img
                src={Imagem10}
                className="imagem10"
                alt="Logo ecovision branco"
              />
              <p className="texto-footer">Use nossos canais de relacionamento para para conhecer mais ou contratar os serviços da nossa plataforma.</p>
            </div>

            <div className="container-informacoes-footer">
              <div className="container-texto-informacoes-footer">
                <h2 className="titulo-informacoes-footer">Plataforma</h2>
                <ul>
                  <a
                    href="/#quem_somos"
                    className="texto-informacoes-footer">Quem somos</a>
                  <a href="/#sobre" className="texto-informacoes-footer">Sobre</a>
                  <a href="/#funcionalidades" className="texto-informacoes-footer">Funcionalidades</a>
                  {/* <a href="/" className="texto-informacoes-footer">Depoimentos</a> */}
                </ul>
              </div>

              <div className="container-texto-informacoes-footer">
                <h2 className="titulo-informacoes-footer">Ajuda</h2>
                <a
                  href="/"
                  className="texto-informacoes-footer">Fale com nossos especialistas</a>
                <a
                  href="/"
                  className="texto-informacoes-footer">perguntas frequentes</a>
              </div>

              <div
                id="contato"
                className="container-texto-informacoes-footer">
                <h2 className="titulo-informacoes-footer">Contatos</h2>
                <p
                  className="texto-footer-contato" style={{ fontWeight: 400 }}>(21)12345-6789</p>
                <p className="texto-footer-contato" style={{ fontWeight: 400 }}>contato@ecovison.app.br</p>
              </div>
            </div>
          </div>
        </footer>
      </div >
    )
  }
}