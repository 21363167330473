import { Component, createRef } from "react";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import HeaderSistemaAdm from "./components/HeaderSistemaAdm";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class AdmFornecedor extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  cepService = new CepService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaFornecedor: new Pessoa(),
    endereco: new Endereco(),
    listaFornecedor: [],
    indexStepAtivo: 0,
    carregando: false,
    salvarCarregando: false,
    dialogNovoFornecedor: false,
    inputError: {
      nome: false,
      tipoDocumento: false,
      email: false,
      telefone: false,
      cep: false,
      logradouro: false,
      numero: false,
      bairro: false,
      cidade: false,
      uf: false,
    },
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }


  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaFornecedor = await this.listar();
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaFornecedor: listaFornecedor,
    })
  }

  async listar() {
    this.setState({ carregando: true });
    const listaPequisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilFornecedor;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPequisa.push(pesquisa);

    if (this.state.filtro.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.nome';
      pesquisa.conteudo = this.state.filtro.nome;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPequisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPequisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPequisa.push(pesquisa);
    }

    let retorno = await this.pessoaService.listarComFiltro(listaPequisa);
    let listaFornecedor = retorno.objeto;

    for (const fornecedor of listaFornecedor) {
      fornecedor.dataCadastro = await this.util.formatarDataBancoParaData(fornecedor.dataCadastro);
    }

    this.setState({
      listaFornecedor: listaFornecedor,
      carregando: false,
    })
    return listaFornecedor
  }

  async salvar() {
    this.setState({ salvarCarregando: true });
    let pessoaFornecedor = this.state.pessoaFornecedor;
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputsOk = true;

    Object.keys(inputError).forEach(pKey => {
      inputError[pKey] = false;
    });

    if (this.state.indexStepAtivo === 0) {
      if (!pessoaFornecedor.nome) {
        inputError.nome = true;
      }

      if (!pessoaFornecedor.tipoDocumento) {
        inputError.tipoDocumento = true;
      }

      if (!pessoaFornecedor.documento) {
        inputError.documento = true;
      } else if (pessoaFornecedor.tipoDocumento === 'CPF' && pessoaFornecedor.documento.length < 14) {
        inputError.documento = true;
      } else if (pessoaFornecedor.tipoDocumento === 'CNPJ' && pessoaFornecedor.documento.length < 15) {
        inputError.documento = true;
      } else if (!await this.util.validarDocumento(pessoaFornecedor.documento)) {
        inputError.documento = true;
      }

      if (!pessoaFornecedor.emailPlataformaLirida) {
        inputError.email = true;
      }

      if (!this.util.validarEmail(pessoaFornecedor.emailPlataformaLirida)) {
        inputError.email = true;
      }

      if (!pessoaFornecedor.telefonePlataformaLirida) {
        inputError.telefone = true;
      }
    }

    if (this.state.indexStepAtivo === 1) {
      if (!endereco.cep) {
        inputError.cep = true;
      }

      if (!endereco.logradouro) {
        inputError.logradouro = true;
      }

      if (!endereco.numero) {
        inputError.numero = true;
      }

      if (!endereco.bairro) {
        inputError.bairro = true;
      }

      if (!endereco.cidade) {
        inputError.cidade = true;
      }

      if (!endereco.uf) {
        inputError.uf = true;
      }
    }

    Object.keys(inputError).forEach(pKey => {
      if (inputError[pKey]) {
        inputsOk = false
      }
    })

    this.setState({
      inputError: inputError,
    })

    if (!inputsOk) {
      this.setState({ salvarCarregando: false })
      return;
    }

    if (this.state.indexStepAtivo < 1) {
      this.setState({
        indexStepAtivo: this.state.indexStepAtivo + 1
      });
      return;
    };

    pessoaFornecedor.seqUsuario = this.constante.seqUsuario;
    pessoaFornecedor.seqPessoaPerfil = this.constante.seqPessoaPerfilFornecedor;
    let retorno = await this.pessoaService.salvar(pessoaFornecedor);
    pessoaFornecedor = retorno.objeto;

    await this.util.enviarEmailAcesso(pessoaFornecedor);

    endereco.seqPessoa = pessoaFornecedor._seqPessoa;
    endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;
    endereco.seqUsuario = this.constante.seqUsuario;

    retorno = await this.pessoaService.salvarEndereco(endereco);
    endereco = retorno.objeto;

    pessoaFornecedor.seqEnderecoPrincipal = endereco._seqEndereco;
    this.pessoaService.salvar(pessoaFornecedor);

    this.toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Fornecedor salvo com sucesso.' });

    window.location.href = ('/admin/fornecedor-detalhe?id=' + pessoaFornecedor._seqPessoa)

    this.setState({
      salvarCarregando: false,
      dialogNovoFornecedor: false,
      pessoaFornecedor: new Pessoa(),
      endereco: new Endereco(),
    })
  }


  async buscarCep() {
    let endereco = this.state.endereco;
    let inputError = this.state.inputError;
    let inputOk = true;

    Object.keys(inputError).forEach(pKeys => {
      inputError[pKeys] = false
    })

    if (!endereco.cep) {
      inputError.cep = true
    }

    Object.keys(inputError).forEach(pKeys => {
      if (inputError[pKeys]) {
        inputOk = false
      }
    });

    this.setState({
      inputError: inputError,
    })

    if (!inputOk) {
      return
    }

    this.setState({ buscarCepCarregando: true });

    let retorno = await this.cepService.buscarCep(endereco.cep);

    endereco.logradouro = retorno.logradouro;
    endereco.complemento = retorno.complemento;
    endereco.bairro = retorno.bairro;
    endereco.cidade = retorno.localidade;
    endereco.uf = retorno.uf;

    this.setState({
      endereco: endereco,
      buscarCepCarregando: false,
    })
  }

  render() {
    return <div>
      <HeaderSistemaAdm />
      {/* DIALOG Fornecedor */}
      <Dialog
        style={{ minWidth: '800px' }}
        header="Novo Fornecedor"
        visible={this.state.dialogNovoFornecedor}
        onHide={() => this.setState({
          dialogNovoFornecedor: false,
        })}
      >
        <Steps
          model={[{
            label: 'Dados do Fornecedor',
          }, {
            label: 'Endereço do Fornecedor',
          },]}
          activeIndex={this.state.indexStepAtivo}
        />

        <form
          style={{ width: '100%', maxWidth: 1000 }}
          onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
          <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
            {this.state.indexStepAtivo === 0 && <>
              <h4 style={{ fontSize: 16, }}>
                <span style={{ color: this.constante.corSecundaria }}>•</span> Dados Pessoais
              </h4>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                <div style={{ width: '100%' }}>
                  <label>Nome</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                    onChange={pEvento => this.setState({
                      pessoaFornecedor: {
                        ...this.state.pessoaFornecedor,
                        nome: pEvento.target.value
                      }
                    })}
                    value={this.state.pessoaFornecedor.nome || ''} />
                  {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                </div>

                <div style={{ width: '100%' && 'red' }}>
                  <label>Tipo de pessoa</label><br />
                  <Dropdown
                    options={[
                      { label: 'Pessoa física', value: 'CPF' },
                      { label: 'Pessoa jurídica', value: 'CNPJ' },
                    ]}
                    onChange={pEvento => this.setState({
                      pessoaFornecedor: {
                        ...this.state.pessoaFornecedor,
                        tipoDocumento: pEvento.value
                      }
                    })}
                    style={{ width: "100%", borderColor: this.state.inputError.tipoDocumento && 'red' }}
                    value={this.state.pessoaFornecedor.tipoDocumento || ''} />
                  {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de pessoa inválido</small>}
                </div>

                {this.state.pessoaFornecedor.tipoDocumento === 'CPF' &&
                  <div style={{ width: '100%' }}>
                    <label>CPF</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                      onChange={pEvento => this.setState({
                        pessoaFornecedor: {
                          ...this.state.pessoaFornecedor,
                          documento: this.util.formatarCPF(pEvento.target.value)
                        }
                      })}
                      value={this.state.pessoaFornecedor.documento || ''} />
                    {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                  </div>}
                {this.state.pessoaFornecedor.tipoDocumento === 'CNPJ' &&
                  <div style={{ width: '100%' }}>
                    <label>CNPJ</label><br />
                    <InputText
                      style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                      onChange={pEvento => this.setState({
                        pessoaFornecedor: {
                          ...this.state.pessoaFornecedor,
                          documento: this.util.formatarCNPJ(pEvento.target.value)
                        }
                      })}
                      value={this.state.pessoaFornecedor.documento || ''} />
                    {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                  </div>}

                <div style={{ width: '100%' }}>
                  <label>Email</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.email && 'red' }}
                    onChange={pEvento => this.setState({
                      pessoaFornecedor: {
                        ...this.state.pessoaFornecedor,
                        emailPlataformaLirida: pEvento.target.value
                      }
                    })}
                    value={this.state.pessoaFornecedor.emailPlataformaLirida || ''} />
                  {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                </div>

                <div style={{ width: '100%' }}>
                  <label>Telefone</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.telefone && 'red' }}
                    onChange={pEvento => this.setState({
                      pessoaFornecedor: {
                        ...this.state.pessoaFornecedor,
                        telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                      }
                    })}
                    value={this.state.pessoaFornecedor.telefonePlataformaLirida || ''} />
                  {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                </div>
              </div></>}

            {this.state.indexStepAtivo === 1 && <>
              <h4 style={{ fontSize: 16, }}>
                <span style={{ color: this.constante.corSecundaria }}>•</span> Endereço do Fornecedor
              </h4>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>

                <div style={{ width: '100%' }}>
                  <label>CEP</label><br />
                  <div className="p-inputgroup">
                    <InputText
                      value={this.state.endereco.cep || ''}
                      type="tel"
                      style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                      onChange={pEvento => this.setState({
                        endereco: {
                          ...this.state.endereco,
                          cep: this.util.formatarCEP(pEvento.target.value)
                        }
                      })}
                    />
                    <Button
                      disabled={this.state.buscarCepCarregando}
                      loading={this.state.buscarCepCarregando}
                      style={{ backgroundColor: this.constante.corPrimaria, color: this.constante.corSecundaria, borderColor: this.constante.corPrimaria }}
                      icon="pi pi-search"
                      type="button"
                      onClick={() => this.buscarCep(this.state.endereco)}
                    />
                  </div>
                  {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
                </div>

                <div style={{ width: '100%' }}>
                  <label>Logradouro</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        logradouro: pEvento.target.value
                      }
                    })}
                    value={this.state.endereco.logradouro || ''} />
                  {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                </div>

                <div style={{ width: '100%' }}>
                  <label>Número</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        numero: pEvento.target.value
                      }
                    })}
                    value={this.state.endereco.numero || ''} />
                  {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
                </div>

                <div style={{ width: '100%' }}>
                  <label>Complemento</label><br />
                  <InputText
                    style={{ width: "100%" }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        complemento: pEvento.target.value
                      }
                    })}
                    value={this.state.endereco.complemento || ''} />
                </div>

                <div style={{ width: '100%' }}>
                  <label>Bairro</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        bairro: pEvento.target.value
                      }
                    })}
                    value={this.state.endereco.bairro || ''} />
                  {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                </div>

                <div style={{ width: '100%' }}>
                  <label>Cidade</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        cidade: pEvento.target.value
                      }
                    })}
                    value={this.state.endereco.cidade || ''} />
                  {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                </div>

                <div style={{ width: '100%' }}>
                  <label>UF</label><br />
                  <InputText
                    style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                    onChange={pEvento => this.setState({
                      endereco: {
                        ...this.state.endereco,
                        uf: pEvento.target.value
                      }
                    })}
                    value={this.state.endereco.uf || ''} />
                  {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
                </div>

              </div>
            </>}

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
              <Button
                type="submit"
                label={this.state.indexStepAtivo < 1 ? 'Próximo' : 'Salvar'} />
            </div>
          </div>
        </form>
      </Dialog>
      {/* FIM DIALOG Fornecedor */}

      <main style={{ width: "100%", margin: "20px auto", padding: 10 }}>
        <Toast ref={this.toast} />
        <div
          style={{
            border: "1px solid lightgray",
            padding: 20,
            borderRadius: 3,
            display: "flex",
            justifyContent: 'space-between'
          }}
        >

          <div>
            <h1 style={{ fontSize: 22, margin: 0 }}>Fornecedors</h1>
            <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Fornecedors</h2>
          </div>

          <div>
            <Button loading={this.state.carregando} label="Novo" className="p-button-raised"
              onClick={() => this.setState({ dialogNovoFornecedor: true })} style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </div>

        {/* FILTRO */}
        <form
          onSubmit={pEvento => {
            pEvento.preventDefault();
            this.listar();
          }}
          style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
            <div>
              <label>Nome</label><br />
              <InputText
                onChange={pEvento => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pEvento.target.value
                    }
                  })
                }}
                value={this.state.filtro.nome || ''}
                placeholder="Nome"
                style={{ width: '100%', height: 40 }}
              />
            </div>


            <div>
              <label>Data cadastro inicial</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Data cadastro final</label><br />
              <Calendar
                onChange={(pEvento) => {
                  this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pEvento.value
                    }
                  })
                }}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="submit"
              loading={this.state.carregando}
              label="Buscar"
              className="p-button-raised" style={{ backgroundColor: this.constante.corPrincipal, borderColor: this.constante.corPrincipal }}
            />
          </div>
        </form>
        {/* FIM FILTRO */}


        {/* TABELA */}
        <DataTable
          showGridlines
          removableSort
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          rows={50}
          rowsPerPageOptions={[50, 60, 70, 80, 100]}
          style={{ width: "100%", marginTop: 30 }}
          value={this.state.listaFornecedor}
          loading={this.state.carregando}
        >
          <Column header='Nome'
            field='nome'
            sortable
            body={pPessoa => <a target='_blank' rel="noreferrer" href={'/admin/fornecedor-detalhe?id=' + pPessoa._seqPessoa}>{pPessoa.nome}</a>}
          />

          <Column header='Data cadastro'
            field='dataCadastro'
            sortable
            body={pPessoa => pPessoa.dataCadastro.toLocaleDateString('pt-br')}
          />
        </DataTable>
        {/* FIM DA TABELA */}
      </main>
    </div>
  }
}