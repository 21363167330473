import { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import HeaderSistemaFornecedor from "./Components/HeaderSistemaFornecedor";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import TipoDocumentoProcesso from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso";
import TipoDocumentoProcessoService from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcessoService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

export default class FornecedorOrdemServico extends Component {
  util = new Util();
  constante = new Constante();
  documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
  tipoDocumentoProcessoService = new TipoDocumentoProcessoService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    listaOrdemServico: [],
    listaDocumentoProcesso: [],
    listaCarregando: false,
    filtro: {
      tipoDocumentoProcesso: new TipoDocumentoProcesso(),
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaOrdemServico = await this.listarDocumento(pessoaUsuario);
    const listaDocumentoProcesso = await this.listarDocumentoProcesso();
    this.listarDocumentoProcesso();
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaOrdemServico: listaOrdemServico,
      listaDocumentoProcesso: listaDocumentoProcesso,
    })
  }

  async listarDocumento(pPessoa) {
    this.setState({ listaCarregando: true });
    let pessoaUsuario = pPessoa;
    if (!pPessoa) {
      pessoaUsuario = this.state.pessoaUsuario;
    }
    const listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'documento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'documento.seq_tipo_documento';
    pesquisa.conteudo = this.constante.seqTipoDocumentoOrdemDeServico;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);


    pesquisa = new Pesquisa();
    pesquisa.campo = 'documento_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);


    if (this.state.filtro.tipoDocumentoProcesso?._seqTipoDocumentoProcesso) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "documento.seq_tipo_documento_processo";
      pesquisa.conteudo = this.state.filtro.tipoDocumentoProcesso._seqTipoDocumentoProcesso;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "documento.data_cadastro";
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro?.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro?.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "documento.data_cadastro";
      pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.filtro?.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.documentoService.listarComFiltro(listaPesquisa);

    let listaOrdemServico = retorno.objeto;


    for (let i = 0; i < listaOrdemServico.length; i++) {
      listaOrdemServico[i].dataCadastro = this.util.formatarDataBancoParaData(listaOrdemServico[i].dataCadastro)
    }


    this.setState({ listaOrdemServico, listaCarregando: false });
    return listaOrdemServico;
  }

  async listarDocumentoProcesso() {
    let retorno = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcesso(this.constante.seqTipoDocumentoOrdemDeServico);
    let listaDocumentoProcesso = retorno.objeto;
    return listaDocumentoProcesso;
  }
  render() {
    return (
      <div>
        <HeaderSistemaFornecedor />
        <main style={{ padding: 10, width: "100%", margin: "20px auto", }}>
          <section
            style={{
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
              display: "flex",
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h1 style={{ fontSize: 22, margin: 0 }}>Ordem de serviço</h1>
              <h2 style={{ fontSize: 20, color: 'gray', margin: 0 }}>Ordem de serviço</h2>
            </div>
          </section>

          <form
            onSubmit={(pEvento) => {
              pEvento.preventDefault();
              this.listarDocumento();
            }}
            style={{
              border: '1px solid lightgray',
              padding: 10,
              borderRadius: 3,
              gap: 20,
              marginTop: 20
            }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
              <div>
                <label>Processo</label><br />
                <Dropdown
                  options={[{ nome: 'Selecione', value: new TipoDocumentoProcesso() }, ...this.state.listaDocumentoProcesso]}
                  optionLabel="nome"
                  placeholder="Selecione"
                  onChange={pEvento => this.setState({
                    filtro: {
                      ...this.state.filtro, tipoDocumentoProcesso: pEvento.target.value
                    }
                  })}
                  value={this.state.filtro.tipoDocumentoProcesso || ''}
                  style={{ width: '100%', height: 40 }}
                />

              </div>

              <div>
                <label>Data cadastro inicial</label><br />
                <Calendar
                  onChange={(pEvento) => {
                    this.setState({
                      filtro: {
                        ...this.state.filtro, dataCadastroInicial: pEvento.value
                      }
                    })
                  }}
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>

              <div>
                <label>Data cadastro final</label><br />
                <Calendar
                  onChange={(pEvento) => {
                    this.setState({
                      filtro: {
                        ...this.state.filtro, dataCadastroFinal: pEvento.value
                      }
                    })
                  }}
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: 10 }}>
              <Button
                label="Buscar"
                type="submit"
                loading={this.state.listaCarregando}
              />
            </div>
          </form>

          <DataTable
            showGridlines
            removableSort
            paginator
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[50, 60, 70, 80, 100]}
            style={{ width: "100%", marginTop: 30 }}
            value={this.state.listaOrdemServico}
            loading={this.state.listaCarregando}
          >
            <Column
              header='Nome'
              field="_tipoDocumentoNome"
              body={pItem => <a href={`/fornecedor/ordem-de-servico-detalhe?id=${pItem._seqDocumento}`} target="_blank" rel="noreferrer">{pItem._tipoDocumentoNome}</a>}
              sortable
            />
            <Column
              header='Processo'
              field="_tipoDocumentoProcessoNome"
              sortable
            />


            <Column
              header='Data cadastro'
              field="dataCadastro"
              sortable
              body={pItem => <p>{pItem.dataCadastro.toLocaleString('pt-br').split(',')[0]}</p>}
            />
          </DataTable>
        </main>
      </div>
    )
  }
}